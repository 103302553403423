import React, { useEffect, useState } from 'react';
import './TokenOwners.css';

const TokenOwners = ({ tokenAddress }) => {
    const [owners, setOwners] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');
    const [cursor, setCursor] = useState(''); // Cursor for pagination

    useEffect(() => {
        if (!tokenAddress) return; // Do not proceed if tokenAddress is not provided

        // Reset state before fetching new data
        setOwners([]);
        setCursor('');
        setError(null);
        setLoading(false);

        // Function to fetch token owners
        const fetchTokenOwners = async (address, currentCursor = '') => {
            setLoading(true);
            try {
                const url = `https://deep-index.moralis.io/api/v2.2/erc20/${address}/owners?chain=0x38&order=DESC&limit=100${currentCursor ? `&cursor=${currentCursor}` : ''}`;
                const response = await fetch(url, {
                    headers: {
                        'accept': 'application/json',
                        'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY
                    }
                });

                if (!response.ok) throw new Error('Failed to fetch data');
                const data = await response.json();
                const newOwners = data.result;

                setOwners(prevOwners => {
                    const updatedOwnersMap = new Map(prevOwners.map(owner => [owner.owner_address, owner]));
                    newOwners.forEach(owner => {
                        if (!updatedOwnersMap.has(owner.owner_address)) {
                            updatedOwnersMap.set(owner.owner_address, owner);
                        }
                    });
                    return Array.from(updatedOwnersMap.values()).sort((a, b) => b.percentage_relative_to_total_supply - a.percentage_relative_to_total_supply);
                });
                setCursor(data.cursor); // Update cursor for next page
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTokenOwners(tokenAddress); // Fetch data for the new address
    }, [tokenAddress]); // Dependency array includes tokenAddress to trigger effect on change

    const handleLoadMore = () => {
        if (cursor) { // Only fetch more if there's a cursor
            fetchTokenOwners(tokenAddress, cursor);
        }
    };

    if (error) return <div>Error: {error}</div>;

    return (
        <div className="token-owners-container">
            <h2>Holders</h2>
            <div className="search-container1">
                <input
                    type="text"
                    placeholder="Search by wallet address..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>

            <table className="token-owners-table">
                <thead>
                    <tr>
                        <th>Percentage of Total Supply</th>
                        <th>Address</th>
                        <th>Balance</th>
                        <th>USD Value</th>
                    </tr>
                </thead>
                <tbody>
                    {owners
                        .filter((owner) => owner.owner_address.toLowerCase().includes(filter.toLowerCase()))
                        .map((owner, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="percentage-bar">
                                        <div
                                            className="percentage-fill"
                                            style={{ width: `${Math.min(100, owner.percentage_relative_to_total_supply.toFixed(2))}%` }}
                                        >
                                            {owner.percentage_relative_to_total_supply.toFixed(2)}%
                                        </div>
                                    </div>
                                </td>
                                <td className={`is-contract-${owner.is_contract}`}>
                                    {owner.owner_address}
                                </td>
                                <td>{parseFloat(owner.balance_formatted).toFixed(4)}</td>
                                <td>
                                    ${new Intl.NumberFormat('en-US', {
                                        minimumFractionDigits: 4,
                                        maximumFractionDigits: 4
                                    }).format(parseFloat(owner.usd_value))}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div>
                {loading ? <p>Loading...</p> : cursor && <button onClick={handleLoadMore}>Load More</button>}
            </div>
        </div>
    );
};

export default TokenOwners;
