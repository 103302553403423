import React from 'react';
import ConnectButton from './lottery'; // Adjust the import path as necessary
import Lottery from './lottery';

function lotterypage({ signer, walletBalance, onSwapComplete, isSidebarVisible }) {
  const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

  return (
    <div className={MainExplorer}>
      <Lottery signer={signer} walletBalance={walletBalance} onSwapComplete={onSwapComplete} />
    </div>
  );
}

export default lotterypage;
