import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi as lockContractABI } from './ContractABI'; // Adjust the path as needed
import { abi as tokenABI } from './faib2ABI'; // Adjust the path as needed
import './staking.css'; // Importing our CSS file
import Modal from './Modal'; // Adjust the path according to your file structure

import logoImage from './AIBOT_logo.png';


const contractAddress = '0xF75D9F5DDFA12bEc753D523A52Aa9e05DAA8F33a';
//const contractAddressFAIB2 = '0xDBe5b93c996d33967a0a3564177E3ddc4CAd3F6e'; Testnet
const contractAddressFAIB2 = '0xaE4c637fb9cb5C151549768a787CCa54c044BdcA'; 


// Web3Modal configuration
const projectId = 'b1b84b2b76426ba5ffae7eb4789161a0'; // Replace with your WalletConnect project ID

const bscMainnet = {
  chainId: 56, // BSC Mainnet Chain ID
  name: 'BSC Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/' // BSC Mainnet RPC URL
};


const metadata = {
  name: 'FAIB | STAKING',
  description: 'Earn extra FAIB2 Tokens',
  url: 'https://staking.flokiaibot.com',
  icons: ['https://flokiaibot.com/static/media/AIBOT_logo.0bf99934a1aec060e3ee.png']
};


export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet], // Use BSC Mainnet configuration
    projectId // Adjust this according to your requirements
  });
  

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [lockAmount, setLockAmount] = useState('');
  const [lockPeriod, setLockPeriod] = useState('');
  const [locksData, setLocksData] = useState([]); // State to store locks data
  const [nextClaimTimes, setNextClaimTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [interestReadyToClaim, setInterestReadyToClaim] = useState({});
  const [apr, setApr] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [customTokenBalance, setCustomTokenBalance] = useState(0);
  const [availablePayout, setAvailablePayout] = useState(0); // Initializes state to hold fetched data
  const [totalLockedTokens, setTotalLockedTokens] = useState(0);
  const [scrollItems, setScrollItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLock, setSelectedLock] = useState(null);
  const restrictedAddresses = [
    '0x8b409a009AC49e03c8F55F8c33b94B55c832AD8e',
    '0x5A275D24daa96B987ae555B1aD9A98ce3ff756cB',
    '0xAddress3...',
    // Add more addresses as needed
  ];

  const isAddressRestricted = (address) => {
    return restrictedAddresses.includes(address.toLowerCase());
  };
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      setScrollItems(currentItems => {
        const baseId = currentItems.length > 0 ? currentItems[currentItems.length - 1].id : 0;
        return [
          ...currentItems,
          { id: baseId + 1, text: `Tokens Available: ${availablePayout} FAIB2` },
          { id: baseId + 2, text: `Available Payout: $ ${(availablePayout * tokenPrice).toFixed(20)}` },
          { id: baseId + 3, text: `Total Locked Tokens: ${totalLockedTokens} FAIB2`},
          { id: baseId + 4, text: `Total Locked Tokens Value: $ ${(totalLockedTokens * tokenPrice).toFixed(20)}` }
        ];
      });
    }, 1000);
    
    return () => clearInterval(interval); // Clean up on component unmount
  }, [availablePayout, tokenPrice, totalLockedTokens]); // Ensure effect runs when these values change
  

  //--------------------------------------------------------------------------------------------------
  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const newAddress = await signer.getAddress();
  
        // Check if the address is restricted
        if (isAddressRestricted(newAddress)) {
          setError("Access Denied: Your wallet address is restricted from using this DApp.");
          await web3Modal.clearCachedProvider(); // Optionally disconnect
          return;
        }
  
        // Set the current user address if it has changed
        if (newAddress !== currentUserAddress) {
          setCurrentUserAddress(newAddress);
          console.log("Current User Address:", newAddress); // Debugging
        }
  
        // Fetch wallet balance and token balance
        fetchWalletBalance();
        fetchTokenBalance();
      }
    };
  
    fetchCurrentUserAddress();
  }, [signer, currentUserAddress]); // Adding currentUserAddress as a dependency to avoid unnecessary re-renders
  
  
  

  //const bscTestnetChainId = '0x61'; // Hexadecimal chain ID for BSC Testnet replace
  const [currentChainId, setCurrentChainId] = useState('');


   // Function to switch to BSC Mainnet
const switchToBscMainnet = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x38' }], // Hexadecimal chain ID for BSC Mainnet
    });
  } catch (error) {
    console.error('Error switching to BSC Mainnet:', error);
  }
};

useEffect(() => {
  if (signer) {
    signer.getChainId().then(chainId => {
      setCurrentChainId(chainId.toString(16));
      if (chainId.toString(16) !== '38') { // Check if it's not BSC Mainnet
        switchToBscMainnet();
      }
    });
  }
}, [signer]);


  // Update the useEffect hook to switch to BSC Testnet
 // useEffect(() => {
  //  if (signer) {
      //signer.getChainId().then(chainId => {
     //   setCurrentChainId(chainId.toString(16));
      //  if (chainId.toString(16) !== bscTestnetChainId) {
      //    switchToBscTestnet();
      //  }
      //});
   // }
 // }, [signer]);

  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    } else {
      console.log("Signer not found"); // Debug: Log if signer is not available
    }
  };

  const fetchTokenBalance = async () => {
    if (signer && currentUserAddress) {
      const tokenContract = new ethers.Contract(contractAddressFAIB2, tokenABI, signer);
      try {
        const tokenBalanceBigNumber = await tokenContract.balanceOf(currentUserAddress);
        const tokenBalance = ethers.utils.formatUnits(tokenBalanceBigNumber, 18); // Assuming the token has 18 decimals
        setCustomTokenBalance(tokenBalance);
      } catch (error) {
        console.error('Error fetching custom token balance:', error);
      }
    }
};

  
  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress('');
          }
        });
      }
    };
  
    subscribeToAccountChanges();
  
    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);
  
//--------------------------------------------------------------------------------------------------

const convertLockPeriodToSeconds = (lockPeriod) => {
  switch (lockPeriod) {
    case '1 day':
      return 1 * 1 * 24 * 60 * 60; // 1 week in seconds
    case '1 week':
      return 1 * 7 * 24 * 60 * 60; // 1 week in seconds
    case '30 days':
      return 30 * 24 * 60 * 60; // 30 days in seconds
    case '90 days':
      return 90 * 24 * 60 * 60; // 90 days in seconds
    case '180 days':
      return 180 * 24 * 60 * 60; // 180 days in seconds
    case '365 days':
        return 365 * 24 * 60 * 60; // 365 days in seconds 
    default:
      console.error('Invalid lock period');
      return 0; // You might want to handle this case differently
  }
};

// Helper functions to format BigNumber and timestamps
const formatBigNumber = (bigNumber, decimals = 18) => ethers.utils.formatUnits(bigNumber, decimals);
const formatDate = (unixTimestampHex) => {
  console.log("Received timestamp:", unixTimestampHex); // Log the raw input for debugging

  // Validate input
  if (unixTimestampHex === undefined || unixTimestampHex === null) {
    console.error('Timestamp is undefined or null');
    return 'Invalid Date';
  }

  // Attempt to convert input to a number
  const unixTimestamp = Number(unixTimestampHex);

  // Check if conversion resulted in a valid number
  if (isNaN(unixTimestamp)) {
    console.error('Converted timestamp is NaN:', unixTimestampHex);
    return 'Invalid Date';
  }

  // Format and return the date
  return new Date(unixTimestamp * 1000).toLocaleString();
};


useEffect(() => {
  fetchWalletBalance(); // Add this line to fetch and display wallet balance
    fetchTokenBalance();
  // Define and call your fetchCurrentUserAddress, fetchWalletBalance, subscribeToAccountChanges here
  // Define and call your switchToBscTestnet, fetchLocksData here
}, [signer, currentUserAddress]); // Ensure you include the necessary dependencies


const approveAndLockTokens = async () => {
  if (!signer) {
    console.error("Wallet not connected");
    return;
  }

  const tokenContract = new ethers.Contract(contractAddressFAIB2, tokenABI, signer);
  const lockContract = new ethers.Contract(contractAddress, lockContractABI, signer);

  try {
    // Convert lock amount to wei
    const lockAmountWei = ethers.utils.parseUnits(lockAmount.toString(), 18);

    // Check current allowance
    const currentAllowance = await tokenContract.allowance(await signer.getAddress(), contractAddress);

    // Approve the lock contract if the current allowance is insufficient
    if (currentAllowance.lt(lockAmountWei)) {
      const approvalTx = await tokenContract.approve(contractAddress, ethers.constants.MaxUint256, { gasLimit: 500000 });
      await approvalTx.wait();
      console.log("Tokens approved successfully");
    }

    // Lock the tokens
    const lockPeriodSeconds = convertLockPeriodToSeconds(lockPeriod); // Use the function here
    const lockTx = await lockContract.lockTokens(lockAmountWei, lockPeriodSeconds, { gasLimit: 1000000 });
    await lockTx.wait();
    console.log("Tokens locked successfully");
    await fetchLocksData();
  } catch (error) {
    console.error("Error locking tokens:", error);
  }
};

// Fetch current user address and set it in state
useEffect(() => {
  if (signer) {
    signer.getAddress().then(setCurrentUserAddress);
  }
}, [signer]);


useEffect(() => {
  // Define and call your fetchCurrentUserAddress, fetchWalletBalance, subscribeToAccountChanges here
  // Define and call your switchToBscTestnet, fetchLocksData here
}, [signer, currentUserAddress]); // Ensure you include the necessary dependencies

// Function to parse and format locks data
const parseLocksData = (data) => {
  return data.map(lock => {
    // Extracting lock details
    const amount = formatBigNumber(lock[0]); // Token amount with 18 decimals
    // Convert start and unlock times to human-readable format and keep raw timestamps
    const startTime = formatDate(lock[1]); // Start time in human-readable format
    const unlockTimeFormatted = formatDate(lock[2]); // Unlock time in human-readable format
    const unlockTimeRaw = lock[2].toNumber(); // Keep raw unlock time for comparison
    const dailyInterest = parseFloat(formatBigNumber(lock[3])).toFixed(2); // Daily interest with 2 decimals
    const interestClaimed = formatBigNumber(lock[4]); // Interest claimed with 18 decimals
    const principalClaimed = lock[5] ? 'Yes' : 'No'; // Principal claimed status
    const lockDays = lock[6].toNumber(); // Number of days the tokens are locked
    const apr = lock[7].toNumber(); // Annual Percentage Rate as a number

    return {
      amount,
      startTime,
      unlockTime: unlockTimeFormatted, // Use formatted unlock time for display
      unlockTimeRaw, // Use raw unlock time for logical comparisons
      dailyInterest,
      interestClaimed,
      principalClaimed,
      lockDays, // Number of days for the lock period
      apr // Annual Percentage Rate
    };
  });
};


// Fetch locks data for the current user
const fetchLocksData = async () => {
  if (signer && currentUserAddress) {
    const contract = new ethers.Contract(contractAddress, lockContractABI, signer);
    try {
      const data = await contract.viewLocks(currentUserAddress);
      const formattedLocksData = parseLocksData(data);
      setLocksData(formattedLocksData);
    } catch (error) {
      console.error('Error fetching locks data:', error);
    }
  }
};

// Invoke fetchLocksData whenever currentUserAddress changes and is not empty
useEffect(() => {
  if (currentUserAddress) {
    fetchLocksData();
  }
}, [currentUserAddress]);

const claimInterest = async (lockIndex) => {
  if (!signer) {
    console.error("Wallet not connected");
    return;
  }

  const contract = new ethers.Contract(contractAddress, lockContractABI, signer);
  
  try {
    // Assuming your contract has a function to claim interest by lock index
    const tx = await contract.claimInterest(lockIndex, { gasLimit: 1000000 });
    await tx.wait();
    console.log(`Interest claimed for lock ${lockIndex + 1}`);
    // Optionally, refresh the locks data after claiming
    fetchLocksData();
    fetchAvailableForPayout();
  } catch (error) {
    console.error("Error claiming interest:", error);
  }
};

const claimPrincipal = async (lockIndex) => {
  if (!signer) {
    console.error("Wallet not connected");
    return;
  }

  const contract = new ethers.Contract(contractAddress, lockContractABI, signer);

  try {
    // Assuming your contract has a function to claim principal by lock index
    const tx = await contract.claimPrincipal(lockIndex, { gasLimit: 1000000 });
    await tx.wait();
    console.log(`Principal claimed for lock ${lockIndex + 1}`);
    // Optionally, refresh the locks data after claiming
    fetchLocksData();
  } catch (error) {
    console.error("Error claiming principal:", error);
  }
};
const fetchNextClaimTime = async (lockIndex) => {
  if (!signer || !currentUserAddress) {
    console.error("Signer or user address not found");
    return;
  }

  const contract = new ethers.Contract(contractAddress, lockContractABI, signer);

  try {
    // Fetch the next claim timestamp using the lockIndex. According to the ABI,
    // the function expects a uint256, which JavaScript numbers and BigNumber types from ethers.js can represent.
    const nextClaimTimestampBigNumber = await contract.nextClaimAvailable(lockIndex);

    // Convert the BigNumber (expected to be a Unix timestamp) to a number. 
    // Note: Ensure the timestamp fits within JavaScript's safe integer range. For Unix timestamps, this is generally safe.
    const nextClaimTimestamp = nextClaimTimestampBigNumber.toNumber();
    
    // Format the Unix timestamp (in seconds) to a human-readable date string.
    const formattedDate = new Date(nextClaimTimestamp * 1000).toLocaleString();

    // Update the React component's state with the formatted date.
    // The state is expected to map lock indices to their next claim available dates.
    setNextClaimTimes(prevState => ({...prevState, [lockIndex]: formattedDate}));
  } catch (error) {
    console.error("Error fetching next claim time:", error);
  }
};

useEffect(() => {
  locksData.forEach((_, index) => fetchNextClaimTime(index));
}, [locksData]);

const fetchInterestReadyToClaim = async (lockIndex) => {
  if (!signer || !currentUserAddress) {
    console.error("Signer or user address not found");
    return;
  }

  const contract = new ethers.Contract(contractAddress, lockContractABI, signer);

  try {
    const interestBigNumber = await contract.getInterestReadyToClaim(lockIndex);
    const interest = ethers.utils.formatUnits(interestBigNumber, 18); // Assuming interest is returned in wei and needs formatting

    setInterestReadyToClaim(prevState => ({...prevState, [lockIndex]: interest}));
  } catch (error) {
    console.error("Error fetching interest ready to claim:", error);
  }
};

useEffect(() => {
  locksData.forEach((_, index) => fetchInterestReadyToClaim(index));
}, [locksData]); // Re-run when locksData changes

const lockOptions = {
  "1 day": { days: 1, apr: 1 },
  "1 week": { days: 7, apr: 10 },
  "30 days": { days: 30, apr: 20 },
  "90 days": { days: 90, apr: 40 },
  "180 days": { days: 180, apr: 60 },
  "365 days": { days: 365, apr: 100 },
};

useEffect(() => {
  if (lockPeriod) {
    setApr(lockOptions[lockPeriod].apr);
  } else {
    setApr(0);
  }
}, [lockPeriod]);

const calculateInterest = (timeFrame) => {
  if (!lockAmount || !apr) return 0;
  const daysInYear = 365;
  const timeFactor = { daily: 1, weekly: 7, monthly: 30 };
  const principal = parseFloat(lockAmount);
  const interest = (principal * apr / 100 * lockOptions[lockPeriod].days) / daysInYear;
  return (interest / lockOptions[lockPeriod].days) * timeFactor[timeFrame];
};

const fetchTokenPrice = async () => {
  try {
    const response = await fetch('https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0xaE4c637fb9cb5C151549768a787CCa54c044BdcA', {
      method: 'GET',
      headers: {
        'accept': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    const tokenPrice = data.data.attributes.token_prices["0xae4c637fb9cb5c151549768a787cca54c044bdca"];
    return parseFloat(tokenPrice); // Convert the string to a floating point number
  } catch (error) {
    console.error('Failed to fetch token price:', error);
    return 0; // Return 0 or handle this case as appropriate
  }
};




useEffect(() => {
  fetchTokenPrice().then(price => {
    console.log("Fetched token price:", price); // Ensure this logs a valid number
    setTokenPrice(parseFloat(price)); // Make sure to parse the price as a float
  });
}, []);



const calculateDollarValueOfInterest = (interest) => {
  if (!tokenPrice || isNaN(interest)) {
    console.log(`Invalid inputs for calculation - Token Price: ${tokenPrice}, Interest: ${interest}`);
    return "Calculating...";
  }
  const dollarValue = interest * tokenPrice;
  return dollarValue.toFixed(10);
};

const calculatePercentage = (percentage) => {
  const amountToLock = customTokenBalance * percentage;
  setLockAmount(amountToLock);
}

// Additional import if needed
// import { abi as contractABI } from './pathToYourABI'; // Adjust based on which contract has the method

const fetchAvailableForPayout = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, lockContractABI, provider); // Adjust as necessary

    const payout = await contract.availableForPayout();
    setAvailablePayout(ethers.utils.formatEther(payout)); // Update state with fetched data
  } catch (error) {
    console.error("Error fetching available for payout:", error);
  }
};


useEffect(() => {
  fetchAvailableForPayout();
}, []); // Calls the function when the component mounts

useEffect(() => {
  const fetchTotalLockedTokens = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(contractAddress, lockContractABI, provider); // Using provider as it's a view function

    try {
      const totalTokensBigNumber = await contract.totalLockedTokens();
      const totalTokens = ethers.utils.formatEther(totalTokensBigNumber);
      setTotalLockedTokens(totalTokens);
    } catch (error) {
      console.error("Error fetching total locked tokens:", error);
    }
  };

  fetchTotalLockedTokens();
}, []); // Add dependencies if needed

const handleLockClick = (lock, index) => {
  setSelectedLock({ ...lock, index }); // Include the index in the state
  setIsModalOpen(true);
};


const closeModal = () => {
  setIsModalOpen(false);
};

useEffect(() => {
  console.log("Locks Data:", locksData);
  console.log("Interest Ready to Claim:", interestReadyToClaim);
}, [locksData, interestReadyToClaim]);

const isInterestAvailable = (index) => {
  const interest = parseFloat(interestReadyToClaim[index] || 0);
  console.log(`Interest for index ${index}: ${interest}`); // Debug log to check values
  return interest > 0;
};




return (
  <div className='shimmer-effect'>
    {/* Address Restriction Check */}
    {error ? (
      <div className="error-message">
        <p>{error}</p>
      </div>
    ) : (
      <>
        {/* Scrolling Banner */}
        <div className="scrolling-banner">
          <div id="scroll-content" className="scroll-content">
            {scrollItems.map(item => (
              <p key={item.id}>{item.text}</p>
            ))}
          </div>
        </div>

        {/* Lock Tokens Input Section */}
        <div className="lock-tokens-inputs">
          <input
            type="number"
            placeholder="Amount to lock"
            value={lockAmount}
            onChange={(e) => setLockAmount(e.target.value)}
            className="lock-amount-input"
          />
          {(lockAmount > 0 && tokenPrice > 0) && (
            <p>$ {(lockAmount * tokenPrice).toFixed(20)}</p>
          )}

          {/* Percentage Buttons */}
          <div className="percentage-buttons-container">
            <button onClick={() => calculatePercentage(0.25)} className="percentage-button">25%</button>
            <button onClick={() => calculatePercentage(0.5)} className="percentage-button">50%</button>
            <button onClick={() => calculatePercentage(0.75)} className="percentage-button">75%</button>
            <button onClick={() => calculatePercentage(0.999)} className="percentage-button">100%</button>
          </div>

          {/* Lock Period Selector */}
          <select
            value={lockPeriod}
            onChange={(e) => setLockPeriod(e.target.value)}
            className="lock-period-selector"
          >
            <option value="">Select Lock Period</option>
            <option value="1 week">1 Week</option>
            <option value="30 days">30 Days</option>
            <option value="90 days">90 Days</option>
            <option value="180 days">180 Days</option>
            <option value="365 days">365 Days</option>
          </select>

          {/* Lock Tokens Button */}
          <button onClick={approveAndLockTokens} className="lock-tokens-button">
            Lock Tokens
          </button>

          {/* Display APR and Interest Calculations */}
          {lockPeriod && (
            <div className="interest-calculations">
              <p>APR: {apr}%</p>
              <div>
                <p>Daily Interest: {calculateInterest('daily').toFixed(2)} FAIB2 - (${calculateDollarValueOfInterest(calculateInterest('daily'))})</p>
                <p>Weekly Interest: {calculateInterest('weekly').toFixed(2)} FAIB2 - (${calculateDollarValueOfInterest(calculateInterest('weekly'))})</p>
                <p>Monthly Interest: {calculateInterest('monthly').toFixed(2)} FAIB2 - (${calculateDollarValueOfInterest(calculateInterest('monthly'))})</p>
              </div>
            </div>
          )}
        </div>

        {/* Locks Data Section */}
        <div className="locks-container">
          {locksData.length > 0 ? (
            locksData.map((lock, index) => {
              const interestAvailable = parseFloat(interestReadyToClaim[index] || 0) > 0;

              // Render only if the principal has not been claimed or if there is interest available
              if (lock.principalClaimed !== 'Yes' || interestAvailable) {
                return (
                  <div key={index} className="lock-info" onClick={() => handleLockClick(lock, index)}>
                    <h3 className="lock-title">Lock {index + 1} - {lock.amount} tokens</h3>
                    <p className="lock-apr">APR: {lock.apr}%</p>
                    <p className="lock-unlock-time">Unlock Time: {lock.unlockTime}</p>
                    <p className="lock-daily-interest">Daily Interest: {lock.dailyInterest} tokens per day</p>
                    <div className="button-group">
                      <button
                        className="btn-claim-interest"
                        onClick={() => claimInterest(index)}
                        disabled={parseFloat(interestReadyToClaim[index] || 0) <= 0}
                      >
                        Claim Interest
                      </button>
                      <button
                        className="btn-claim-principal"
                        onClick={() => claimPrincipal(index)}
                        disabled={new Date().getTime() < new Date(lock.unlockTimeRaw * 1000).getTime() || lock.principalClaimed === 'Yes'}
                      >
                        Claim Principal
                      </button>
                    </div>
                  </div>
                );
              } else {
                console.log(`Lock at index ${index} not rendered. Interest available: ${interestAvailable}`);
                return null;
              }
            })
          ) : (
            <p className="no-locks">No locks found for this user.</p>
          )}
        </div>

        {/* Modal for Lock Details */}
        <Modal isOpen={isModalOpen} close={closeModal}>
          {selectedLock && (
            <div>
              <h3 className="lock-title">Lock Details</h3>
              <p className="lock-apr">Lock # {selectedLock.index + 1}</p>
              <p className="lock-apr">APR: {selectedLock.apr}%</p>
              <p className="lock-days">Lock Period: {selectedLock.lockDays} days</p>
              <p className="lock-amount">Amount: {selectedLock.amount} tokens</p>
              <p className="lock-start-time">Start Time: {selectedLock.startTime}</p>
              <p className="lock-unlock-time">Unlock Time: {selectedLock.unlockTime}</p>
              <p className="lock-daily-interest">Daily Interest: {selectedLock.dailyInterest} tokens per day</p>
              <p className="lock-interest-claimed">Interest Claimed: {selectedLock.interestClaimed} tokens</p>
              <p className="lock-principal-claimed">Principal Claimed: {selectedLock.principalClaimed}</p>
              <p className="lock-next-claim">Next Claim Available: {
                error ? "Error loading data" :
                nextClaimTimes[selectedLock.index] ? nextClaimTimes[selectedLock.index] : (loading ? 'Loading...' : 'Unavailable')
              }</p>
              <p className="lock-interest-ready">Interest Ready to Claim: {interestReadyToClaim[selectedLock.index] ? `${interestReadyToClaim[selectedLock.index]} tokens` : 'Loading...'}</p>

              <div className="button-group">
                <button
                  className="btn-claim-interest"
                  onClick={() => claimInterest(selectedLock.index)}
                  disabled={parseFloat(interestReadyToClaim[selectedLock.index] || '0') === 0}
                >
                  Claim Interest
                </button>
                <button
                  className="btn-claim-principal"
                  onClick={() => claimPrincipal(selectedLock.index)}
                >
                  Claim Principal
                </button>
              </div>
            </div>
          )}
        </Modal>
      </>
    )}
  </div>
);

  
}
