import React, { useState } from 'react';
import Sidebar from './sidebar';
import TokenExplorer from './TokenExplorer';

const MainExplorer = ({ isSidebarVisible }) => {
    const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

      
  return (
    <div className={MainExplorer}>
      <TokenExplorer />
    </div>
  );
};

export default MainExplorer;
