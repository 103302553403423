import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import Moralis from 'moralis';
import './TokenExplorer2.css';
import TokenDetails from './gopluss';
import SwapComponent from '../../Swap1/SwapComponent'; // Adjust the import path as necessary
import './token_explorer_swap.css'
import TokenOwners from './TokenOwners';
import { FaCheck, FaTimes, FaBalanceScale, FaShieldAlt, FaRegCalendarAlt } from 'react-icons/fa';



import default_logo from '../images/defaul_logo.png';
import chartIcon from '../images/chartIcon.webp';
import securityIcon from '../images/security.png'
import metamask_logo from '../images/metamask.png';
import swap_logo from '../images/swap1.png';
import { FaCopy } from 'react-icons/fa'; // Importing the copy icon
import telegram_logo from '../images/telegram_logo.svg'
import x_logo from '../images/x_logo.svg'
import web_logo from '../images/website_logo.svg'
import holders_logo from '../images/holders.svg'
import FAIB2_logo from '../images/AIBOT_logo.png';






const TokenExplorer2 = ({ signer, walletBalance, onSwapComplete, isSidebarVisible }) => {
    const { address } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [tokenData, setTokenData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate
    const [selectedContent, setSelectedContent] = useState('content1');
    const [isSwapVisible, setIsSwapVisible] = useState(false); // State to manage swap component visibility
    const [auditData, setAuditData] = useState(null);
    const [tokenInfo, setTokenInfo] = useState(null);


    const toggleSwapVisibility = () => {
        setIsSwapVisible(!isSwapVisible);
    };

    const fetchTokenInfo = async (tokenAddress) => {
      const url = `https://public-api.dextools.io/trial/v2/token/bsc/${tokenAddress}`;
      const apiKey = process.env.REACT_APP_DEXTOOLS_API_KEY;
  
      try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'x-api-key': apiKey
              }
          });
  
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          setTokenInfo(data.data); // Store the token information
      } catch (error) {
          console.error('Error fetching token info:', error);
          setError(error.toString());
      }
  };

  useEffect(() => {
    if (address) {
        fetchTokenInfo(address); // New function to fetch additional token info
    }
}, [address]);


    const fetchAuditData = async (tokenAddress) => {
      const url = `https://public-api.dextools.io/trial/v2/token/bsc/${tokenAddress}/audit`;
      const apiKey = process.env.REACT_APP_DEXTOOLS_API_KEY;
  
      try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'x-api-key': apiKey
              }
          });
  
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          setAuditData(data.data); // Set the audit data in state
      } catch (error) {
          console.error('Error fetching audit data:', error);
          setError(error.toString()); // Assuming you have a state `setError` for handling errors
      }
  };

  useEffect(() => {
    if (!address) return;
    fetchAuditData(address); // Fetch audit data
}, [address]);

    



    useEffect(() => {
        if (!address) return; // If there's no address in the URL, skip fetching
        const fetchTokenData = async () => {
            try {
                const response = await Moralis.EvmApi.token.getTokenPrice({
                    chain: "0x38",
                    include: "percent_change",
                    address,
                });
                setTokenData(response.raw);
            } catch (error) {
                console.error("Error fetching token data:", error);
                setError(error.toString());
            }
        };
        fetchTokenData();
    }, [address]);

 
  

    const handleSearch = async (event) => {
        event.preventDefault();
        setError(null);
        try {
            const response = await Moralis.EvmApi.token.getTokenPrice({
                chain: "0x38",
                include: "percent_change",
                address: searchTerm, // Search term is used as the address
            });
            setTokenData(response.raw);
            // Navigate to the new URL with the token address
            navigate(`/TokenExplorer/${searchTerm}`);
        } catch (error) {
            console.error("Error fetching token price:", error);
            setError(error);
        }
    };

    

    const addToMetaMask = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage) => {
        try {
          if (window.ethereum && window.ethereum.isMetaMask) {
            // Check if MetaMask is connected to the Binance Smart Chain
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId !== '0x38') { // 0x38 is the chainId for BSC
              alert('Please switch to the Binance Smart Chain network in your MetaMask wallet.');
              return;
            }
      
            const wasAdded = await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: tokenAddress,
                  symbol: tokenSymbol,
                  decimals: tokenDecimals,
                  image: tokenImage,
                },
              },
            });
      
            if (wasAdded) {
              console.log('Token has been added to your MetaMask wallet.');
            } else {
              console.log('Token addition was rejected.');
            }
          } else {
            alert('MetaMask is not installed. Please install MetaMask and try again.');
          }
        } catch (error) {
          console.error('Error adding token to MetaMask', error);
        }
      };
      
      const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            // This is where you might show a notification or toast to the user indicating success
            console.log('Address copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      };

      const specialTokens = {
        '0xae4c637fb9cb5c151549768a787cca54c044bdca': {  // Example token address
          twitter: 'https://twitter.com/FAIB2_0',
          telegram: 'https://t.me/FlokiAIBot503',
          website: 'https://flokiaibot.com/',
          description: 'At the heart of Floki AI BOT 2.0 is our cutting-edge technology designed to redefine the standards of security and efficiency in cryptocurrency trading.',
          logo: FAIB2_logo  // This uses the imported image directly
          

        },
        // Add more special tokens as needed
      };

      const isValidImageUrl = (url) => {
        // Returns true if the URL is a string that starts with http, https, or is a local static path
        return typeof url === 'string' && (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('/static/media/'));
    };
    
    
      
      const getImageUrl = () => {
        const specialTokenLogo = specialTokens[tokenInfo?.address]?.logo;
      
        if (isValidImageUrl(specialTokenLogo)) {
          return specialTokenLogo;
        }
      
        // Check for token logos provided by Dextools or other sources
        const tokenLogo = tokenData?.tokenLogo || tokenInfo?.logo;
      
        if (isValidImageUrl(tokenLogo)) {
          return tokenLogo;
        }
      
        return default_logo;
      };
      
      const description = tokenInfo?.description || specialTokens[tokenInfo?.address]?.description || "No description available.";

      
    return (
        <div className='token_explorer2'>
            <div className="search-container">
                <form onSubmit={handleSearch} className="search-form">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search for any token or wallet"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button type="submit" className="search-button">Search</button>
                </form>
            </div>
            {tokenData && (
                <div className="token-data">
                    <div className='token-data-name'> 
                    <img 
  src={getImageUrl()} 
  alt="Token Logo"
  style={{ width: '100px', height: '100px' }}
  onError={(e) => { e.target.onerror = null; e.target.src = default_logo; }}
/>


                        <h3>{tokenData.tokenName} ({tokenData.tokenSymbol})</h3>
                    </div>
                    <div className='token-data-price'>
                        <h1>$ {tokenData.usdPriceFormatted}</h1>
                        <p style={{ color: parseFloat(tokenData["24hrPercentChange"]) >= 0 ? 'green' : 'red', fontSize: '20px', marginLeft: '10px' }}>
                {parseFloat(tokenData["24hrPercentChange"]).toFixed(2)}%
            </p>
                    </div> 

                    <div className='token_description'>
                    <h1>{description}</h1>


                      </div>
                    <div className='token-data-buttons'> 
                    <button onClick={() => copyToClipboard(tokenData.tokenAddress)} className="copy-button">
              <FaCopy />
            </button>
                    <button 
  className="metamask_button" 
  onClick={() => addToMetaMask(
    tokenData.tokenAddress,
    tokenData.tokenSymbol,
    tokenData.tokenDecimals, // Ensure this value is correctly provided
    tokenData.tokenLogo // Make sure this is a valid image URL
  )}
>
  <img src={metamask_logo} alt="MetaMask" />
</button>

<button className='social_logos'
  onClick={() => {
    const twitterLink = specialTokens[tokenInfo?.address]?.twitter || tokenInfo?.socialInfo.twitter;
    if (twitterLink) {
      window.open(twitterLink, '_blank', 'noopener,noreferrer');
    }
  }}
  disabled={!specialTokens[tokenInfo?.address]?.twitter && !tokenInfo?.socialInfo.twitter}
>
  <img src={x_logo} alt="Twitter" style={{ width: '30px', height: '30px' }} />
</button>

<button className='social_logos'
  onClick={() => {
    const telegramLink = specialTokens[tokenInfo?.address]?.telegram || tokenInfo?.socialInfo.telegram;
    if (telegramLink) {
      window.open(telegramLink, '_blank', 'noopener,noreferrer');
    }
  }}
  disabled={!specialTokens[tokenInfo?.address]?.telegram && !tokenInfo?.socialInfo.telegram}
>
  <img src={telegram_logo} alt="Telegram" style={{ width: '30px', height: '30px' }} />
</button>

<button className='social_logos'
  onClick={() => {
    const websiteLink = specialTokens[tokenInfo?.address]?.website || tokenInfo?.socialInfo.website;
    if (websiteLink) {
      window.open(websiteLink, '_blank', 'noopener,noreferrer');
    }
  }}
  disabled={!specialTokens[tokenInfo?.address]?.website && !tokenInfo?.socialInfo.website}
>
  <img src={web_logo} alt="Website" style={{ width: '30px', height: '30px' }} />
</button>


<button className="trade_button" onClick={toggleSwapVisibility}>
            Trade
             </button>

     

                    </div>
                    {auditData && (
    <div className="audit-ticker">
        <div className="audit-ticker-content">
            {[...Array(2)].map((_, i) => (
                <React.Fragment key={i}>
                    <div className="audit-data-point"><FaCheck /> Open Source: {auditData.isOpenSource}</div>
                    <div className="audit-data-point"><FaTimes /> Honeypot: {auditData.isHoneypot}</div>
                    <div className="audit-data-point"><FaBalanceScale /> Mintable: {auditData.isMintable}</div>
                    <div className="audit-data-point"><FaShieldAlt /> Proxy: {auditData.isProxy}</div>
                    <div className="audit-data-point"><FaTimes /> Blacklisted: {auditData.isBlacklisted}</div>
                    <div className="audit-data-point"><FaBalanceScale /> Sell Tax: {(auditData.sellTax.min * 100).toFixed(2)}%</div>
                    <div className="audit-data-point"><FaBalanceScale /> Buy Tax: {(auditData.buyTax.min * 100).toFixed(2)}%</div>
                    <div className="audit-data-point"><FaTimes /> Potentially Scam: {auditData.isPotentiallyScam}</div>
                </React.Fragment>
            ))}
        </div>
    </div>
)}




                </div>
            )}
 {/* Conditional rendering for the SwapComponent based on isSwapVisible state */}
 {isSwapVisible && (
            <div className='token_explorer_swap'>
                <SwapComponent
                    signer={signer}
                    walletBalance={walletBalance}
                    onSwapComplete={onSwapComplete}
                    tokenIn={tokenData ? tokenData.address : ''}
                    tokenOut={tokenData ? tokenData.tokenAddress : ''}
                    onClose={() => setIsSwapVisible(false)}
                />
            </div>
        )}
        {error && <p className="error-message">Error fetching token data: {error.message}</p>}
            <div className="content-selector">
                <button onClick={() => setSelectedContent('content1')}>
                <img src={chartIcon} alt="Chart Icon" style={{ width: '30px', height: '30px', }} />
                    Price Chart
                    </button>
                    
                <button onClick={() => setSelectedContent('content2')}>
                <img src={securityIcon} alt="Chart Icon" style={{ width: '30px', height: '30px', }} />
                    Security</button>
                {/* Additional content selection buttons */}
                <button onClick={() => setSelectedContent('content3')}>
                <img src={holders_logo} alt="Chart Icon" style={{ width: '30px', height: '30px', background:'#83a7be', borderRadius:'100px'}} />
                    Holders</button>
            </div>

            <div className="selected-content">
                {selectedContent === 'content1' && 
                <div>
{address && (
                  
                    <h1>
                    {tokenData?.tokenName} Price Chart
                    </h1>
                    )}
                   {selectedContent === 'content1' && address && (
  <div id="dexscreener-embed">
    <iframe 
      src={`https://dexscreener.com/bsc/${address}?embed=1&theme=dark`} 
      title="Dexscreener Embed"
      allowFullScreen>
    </iframe>
  </div>
)}

                    
                    </div>}


                
                    {selectedContent === 'content2' && address && (
                                        <div>
                          <TokenDetails address={address} />

                    </div>)}

                    {selectedContent === 'content3' && address && (
                                        <div>
                          <TokenOwners tokenAddress={address} />

                    </div>)}

               
            </div>
            
        </div>
    );
};

export default TokenExplorer2;
