import React from 'react';
import SwapComponent from './SwapComponent'; // Adjust the import path as necessary

function SwapContainer({ signer, walletBalance, onSwapComplete, isSidebarVisible }) {
  const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

  return (
    <div className={MainExplorer}>
      <SwapComponent signer={signer} walletBalance={walletBalance} onSwapComplete={onSwapComplete} />
    </div>
  );
}

export default SwapContainer;
