import React from 'react';
import './TransactionModal.css'; // Import your modal styles

const TransactionModal = ({ isVisible, message, imageSrc, onClose }) => {
    if (!isVisible) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-lotteria-status">
                <img src={imageSrc} alt="Transaction Status" className="transaction-status-image" />
                <h3>Transaction Status</h3>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default TransactionModal