import React, { useState, useEffect } from 'react';
import './swap.css'; // Assuming you have a CSS file for modal styles
import Moralis from 'moralis';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';


// Modal component for simplicity
const Modal = ({ children }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-box">
        {children}
      </div>
    </div>
  );
};

const WalletSelectorModal = ({ signer, walletBalance, onSwapComplete, isSidebarVisible }) => {
  const [userAddress, setUserAddress] = useState('');
  const [tokens, setTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const [isHoneypotFilterActive, setIsHoneypotFilterActive] = useState(true);
  const navigate = useNavigate();

  const handleTokenClick = (tokenAddress) => {
    navigate(`/TokenExplorer/${tokenAddress}`);
  };
  

  const toggleHoneypotFilter = () => {
    setIsHoneypotFilterActive(!isHoneypotFilterActive);
  };

  const checkIsHoneypot = async (tokenAddress) => {
    const url = `https://api.honeypot.is/v2/IsHoneypot?address=${tokenAddress}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking Honeypot:', error);
      return null; // Return null or decide on a fallback or error handling strategy
    }
  };
  
  
  const fetchTokenBalances = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await Moralis.EvmApi.wallets.getWalletTokenBalancesPrice({
        chain: "0x38", // Binance Smart Chain Mainnet
        address: userAddress,
      });
      if (!response || !response.result) {
        throw new Error('Unexpected response structure');
      }
  
      // Enrich tokens with Honeypot check
      const tokensWithHoneypotData = await Promise.all(response.result.map(async (token) => {
        // Check if token address is the bypass address
        if (token.tokenAddress._value.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
          // Modify or skip the Honeypot check for this address as needed
          return { 
            ...token, 
            honeypotData: {
              // You can default to safe values or any placeholder data
              summary: { risk: "N/A" },
              honeypotResult: { isHoneypot: false },
              simulationSuccess: true
            }
          };
        } else {
          // Proceed with Honeypot check for other addresses
          const honeypotData = await checkIsHoneypot(token.tokenAddress._value);
          return { ...token, honeypotData }; // Merge token data with its Honeypot check result
        }
      }));
  
      setTokens(tokensWithHoneypotData);
    } catch (error) {
      console.error("Failed to fetch token balances or Honeypot data:", error);
      setError("Failed to fetch token balances or Honeypot data. Please try again later.");
      setTokens([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    if (userAddress) {
      fetchTokenBalances();
    }
  }, [userAddress]);

  useEffect(() => {
    const fetchUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setUserAddress(address);
      }
    };
    fetchUserAddress();
  }, [signer]);


  const formatBalance = (balance, decimals) => {
    try {
      const balanceStr = String(balance);
      const formattedBalance = ethers.utils.formatUnits(balanceStr, decimals);
      return Number(formattedBalance).toFixed(4); // Convert to number and format to 4 decimal places
    } catch (error) {
      console.error(`Error formatting balance: ${error.message}`);
      return "0.0000";
    }
  };

  function formatNumber(value, maxFractionDigits = 14) {
    // Check for very small values that are effectively zero
    if (Math.abs(value) < 1.0e-14) {
      return "0";
    }
  
    // Convert to a string with up to 14 decimal places
    let str = value.toFixed(maxFractionDigits);
  
    // Remove trailing zeros
    str = str.replace(/(\.\d*?[1-9])0+$/, "$1");
  
    // Remove decimal point if it's the last character
    str = str.replace(/\.$/, "");
  
    // Format large numbers with commas and maintain the original precision for small numbers
    const parts = str.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  
  function formatUsdPrice(price) {
    if (price === null || price === undefined) {
      return 'N/A';
    }
    return `$${formatNumber(price)}`;
  }

  return (
      <div className="modal-content-wallet">
        <div className="modal-header">
        </div>

        <div className='wallet-balances-swap'>
          <h1>My Wallet</h1>
          <input
            type="text"
            placeholder="Search by token name || Ticket || Adress..."
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
         
          <div className='wallet-balances-swap'>
            <div className='token-balances-container'>
            <div className="toggle-switch">
            <h2>Honeypot Filter</h2>

  <input
    id="honeypot-filter-toggle"
    type="checkbox"
    checked={isHoneypotFilterActive}
    onChange={toggleHoneypotFilter}
  />
  <label htmlFor="honeypot-filter-toggle" className="toggle-label">
    Honeypot Filter
  </label>
</div>

              <h2 className='token-balances-header'>Token Balances</h2>
              <table className='token-table'>
              
                <thead>
                  <tr>
                    <th>TOKEN</th>
                    <th>BALANCE</th>
                    <th>Token Info</th>

                  </tr>
                </thead>
                <tbody>
  {tokens
    .filter(token => 
      (search === '' ||
      token.name.toLowerCase().includes(search.toLowerCase()) ||
      token.symbol.toLowerCase().includes(search.toLowerCase()) ||
      token.tokenAddress._value.toLowerCase().includes(search.toLowerCase())) &&
      (!isHoneypotFilterActive || 
        (!token.honeypotData || 
         token.honeypotData?.honeypotResult?.isHoneypot === false) &&
        (token.honeypotData?.simulationResult?.sellTax <= 49 || 
         token.honeypotData?.simulationResult?.sellTax === undefined))
    ).map((token) => (
      <tr 
        key={token.tokenAddress._value} 
        onClick={() => handleTokenClick(token.tokenAddress._value)}
        style={{ cursor: 'pointer' }} 
        className="table-row"
      >
        <td>
          {token.name} ({token.symbol})<br />
          {formatUsdPrice(token.usdPrice)} <br />
        </td>
        <td className="balance-usd">
          {formatBalance(token.balance, token.decimals)}<br />
          ${Number(token.usdValue).toFixed(2)}
        </td>
        <td>
          <span className="buy">BUY {Number(token.honeypotData?.simulationResult?.buyTax).toFixed(2) || 0}%</span> | 
          <span className="sell">SELL {Number(token.honeypotData?.simulationResult?.sellTax).toFixed(2) || 0}%</span>        
          <span className="risk">Risk: {token.honeypotData?.summary?.risk || 'Unknown'}</span>
        </td>
      </tr>
    ))
  }
</tbody>


              </table>
              {isLoading && <p>Loading tokens...</p>}
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </div>
      </div>
  );
};

export default WalletSelectorModal;
