import React, { useState } from 'react';
import TokenExplorer from './TokenExplorer_TV';

const MainExplorer_tv = ({ isSidebarVisible }) => {
    const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

      
  return (
    <div className={MainExplorer}>
      <TokenExplorer />
    </div>
  );
};

export default MainExplorer_tv;
