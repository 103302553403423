import React, { useEffect, useState } from 'react';
import Moralis from 'moralis';
import './TokenExplorer.css'; // Make sure this file exists and is correctly styled

const TokenDetailsModal = ({ isOpen, onClose, token }) => {
    if (!isOpen || !token) return null; // Don't render the modal if it's not open or if there's no token data

    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="TE_Token_Info" onClick={e => e.stopPropagation()}> {/* Prevents modal close when clicking inside the modal */}
                <div className="modal-header">
                    <h2>{token.name} ({token.symbol.toUpperCase()})</h2>
                    <button onClick={onClose} className="modal-close-button">&times;</button>
                </div>
                <div className="modal-body">
                    <img src={token.logo} alt={`${token.name} logo`} className="token-logo-modal" />
                    <p><strong>Market Cap:</strong> ${parseFloat(token.market_cap_usd).toLocaleString()}</p>
                    <p><strong>Price:</strong> ${parseFloat(token.usd_price).toLocaleString()} USD</p>
                    <p><strong>24h Volume:</strong> ${parseFloat(token.total_volume).toLocaleString('en-US', { maximumFractionDigits: 2 })}</p>

                    <p><strong>24h Change:</strong> {parseFloat(token.usd_price_24hr_percent_change).toFixed(2)}%</p>
                    <p><strong>7d Change:</strong> {parseFloat(token.usd_price_7d_percent_change).toFixed(2)}%</p>
                    <p><strong>30d Change:</strong> {parseFloat(token.usd_price_30d_percent_change).toFixed(2)}%</p>
                    <p><strong>Circulating Supply:</strong> {parseFloat(token.circulating_supply).toLocaleString()}</p>
                    <p><strong>Total Supply:</strong> {token.total_supply ? parseFloat(token.total_supply).toLocaleString() : 'N/A'}</p>
                    <p><strong>Max Supply:</strong> {token.max_supply ? parseFloat(token.max_supply).toLocaleString() : 'N/A'}</p>
                </div>
            </div>
        </div>
    );
};



const TokenExplorer_tv = ({ sidebarMinimized }) => {
    const [cryptos, setCryptos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ field: null, direction: 'ascending' });
    const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedToken, setSelectedToken] = useState(null);

const handleTokenClick = (token) => {
    setSelectedToken(token);
    setIsModalOpen(true);
};


    useEffect(() => {
        const initMoralis = async () => {
            try {
                const response = await Moralis.EvmApi.marketData.getTopCryptoCurrenciesByTradingVolume({});
                setCryptos(response.raw); // Assuming 'raw' contains the array of cryptocurrencies
            } catch (e) {
                console.error(e);
                setError(e.toString());
            } finally {
                setIsLoading(false);
            }
        };

        initMoralis();
    }, []);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.field === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ field: key, direction });
    };

    const sortedCryptos = React.useMemo(() => {
        let sortableItems = [...cryptos];
        if (sortConfig.field !== null) {
            sortableItems.sort((a, b) => {
                // Adjust for string fields
                if (sortConfig.field === 'name' || sortConfig.field === 'symbol') {
                    if (a[sortConfig.field].toLowerCase() < b[sortConfig.field].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.field].toLowerCase() > b[sortConfig.field].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                } else {
                    // Ensure numerical comparison for other fields
                    const aValue = parseFloat(a[sortConfig.field]);
                    const bValue = parseFloat(b[sortConfig.field]);
                    return (aValue < bValue ? -1 : 1) * (sortConfig.direction === 'ascending' ? 1 : -1);
                }
                return 0;
            });
        }
        return sortableItems;
    }, [cryptos, sortConfig]);

    return (
        <div className='crypto-explorer'>
            <h1> Today's Cryptocurrency Prices by Trading Volume</h1>
            

            {isLoading ? (
                <p>Loading data...</p>
            ) : error ? (
                <p>Error fetching data: {error}</p>
            ) : (
                <>
                    <table className="crypto-table">
                        <thead className="crypto-table-header">
                            
                            <tr className="crypto-table-row-header">
                                <th>#</th>
                                <th>Logo</th>
                                <th onClick={() => requestSort('name')}>Name (Symbol)</th>
                                <th onClick={() => requestSort('market_cap_usd')}>Market Cap USD</th>
                                <th onClick={() => requestSort('total_volume')}>24h Volume</th>
                                <th onClick={() => requestSort('usd_price')}>Price USD</th>
                                <th onClick={() => requestSort('usd_price_1hr_percent_change')}>1h Change</th>
                                <th onClick={() => requestSort('usd_price_24hr_percent_change')}>24h Change</th>
                                <th onClick={() => requestSort('usd_price_7d_percent_change')}>7d Change</th>
                                <th onClick={() => requestSort('usd_price_30d_percent_change')}>30d Change</th>

                            </tr>
                        </thead>
                        <tbody>
                            {sortedCryptos.map((crypto, index) => (
                               <tr key={index} className="crypto-table-row" onClick={() => handleTokenClick(crypto)}>
                                    <td>{index + 1}</td>
                                    <td><img src={crypto.logo} alt={crypto.name} className="crypto-logo" /></td>
                                    <td className="crypto-name">{crypto.name} ({crypto.symbol})</td>
                                    <td className="crypto-market-cap">${parseFloat(crypto.market_cap_usd).toLocaleString('en-US', { maximumFractionDigits: 6 })}</td>
                                    <td className="crypto-volume">${parseFloat(crypto.total_volume).toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                    <td className="crypto-price">${parseFloat(crypto.usd_price).toLocaleString('en-US', { maximumFractionDigits: 10 })}</td>
                                    <td className="crypto-change">{parseFloat(crypto.usd_price_1hr_percent_change).toFixed(4)}%</td>
                                    <td className="crypto-change">{parseFloat(crypto.usd_price_24hr_percent_change).toFixed(4)}%</td>
                                    <td className="crypto-change">{parseFloat(crypto.usd_price_7d_percent_change).toFixed(4)}%</td>
                                    <td className="crypto-change">{parseFloat(crypto.usd_price_30d_percent_change).toFixed(4)}%</td>


                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
                    <TokenDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} token={selectedToken} />

        </div>
    );
};

export default TokenExplorer_tv;
