import React, { useState, useEffect, createContext } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi } from './ContractABI';
import '../styles/presaleInterface.css';
import '../styles/Lottery.css';



import { saleTokenAddress, saleTokenAbi } from './saleToken';
 
const contractAddress = '0xF1bf9d930cB2c63059155B05b294545503797964';

// Web3Modal configuration
const projectId = 'd1c2210ee614f7c716a2e89e2aa68b50'; // Replace with your WalletConnect project ID
const infuraUrl = 'https://goerli.infura.io/v3/15083069ebbe40b6947abaabe8a27821'; // Infura URL for Goerli

const bscMainnet = {
  chainId: 56, // BSC Mainnet Chain ID
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};

const metadata = {
  name: 'Floki AI BOT NFT',
  description: 'FAIB NFT',
  url: 'https://flokiaibot.com',
  icons: ['https://flokiaibot.com/wp-content/uploads/2023/04/cropped-photo_2023-04-26-17.26.44.jpeg/']
};

const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId
    
  });

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [currentPrice, setCurrentPrice] = useState('0');
  const [priceInUSD, setPriceInUSD] = useState('0');
  const [priceShift, setPriceShift] = useState(ethers.BigNumber.from(0));
  const nextPrice = parseFloat(priceInUSD) + parseFloat(priceShift);
  const [presaleAmount, setPresaleAmount] = useState(0); // State to hold the fetched presale amount
  const [totalTokensSold, setTotalTokensSold] = useState(0); // State to hold the fetched totalTokensSold value
  const sellPercentage = (totalTokensSold / presaleAmount) * 100;
  const [totalSoldPrice, setTotalSoldPrice] = useState('0'); // State to hold the fetched totalSoldPrice value
  const [saleStartTime, setSaleStartTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState('');
  const [countdown, setCountdown] = useState('');
  const [daysPast, setDaysPast] = useState(0); // Define daysPast as a state variable
  const [purchasedTokens, setPurchasedTokens] = useState(0);
  const [claimStartTime, setClaimStartTime] = useState(0); // State to hold the claim start time
  const [isClaimButtonLocked, setIsClaimButtonLocked] = useState(true); // State to lock/unlock the claim button
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [estimatedCostInUSD, setEstimatedCostInUSD] = useState('0');
  const [buyError, setbuyError] = useState('');

  const calculateEstimatedCostInUSD = () => {
    if (purchaseAmount && priceInUSD) {
      const costInUSD = parseFloat(purchaseAmount) * parseFloat(priceInUSD);
      setEstimatedCostInUSD(costInUSD.toFixed(6)); // Adjust decimal places as needed
    }
  };
  
  useEffect(() => {
    calculateEstimatedCostInUSD();
  }, [purchaseAmount, priceInUSD]);
  
  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        // Convert the balance to Ether (wei to Ether) and round it to 4 decimal places
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    }
  };

  
  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const newAddress = await signer.getAddress();
        if (newAddress !== currentUserAddress) {
          setCurrentUserAddress(newAddress);
          console.log("Current User Address:", newAddress); // Add this line for debugging

        }
      }
    };
  
    fetchCurrentUserAddress();
    fetchWalletBalance(); // Assuming this doesn't cause state updates leading to re-rendering.
  }, [signer]);


  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress('');
          
          }
        });
      }
    };
  
    subscribeToAccountChanges();
  
    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);



  const fetchCurrentPrice = async (tokenAmountString) => {
    try {
      const tokenAmount = tokenAmountString ? ethers.BigNumber.from(tokenAmountString) : ethers.BigNumber.from("1");
      const prices = await contract.getPrice(tokenAmount);
      const formattedPriceInNative = ethers.utils.formatUnits(prices[0], 'ether');
      const formattedPriceInUSD = ethers.utils.formatUnits(prices[1], 18); // Adjust to the correct decimal places
  
      console.log('Fetched priceInUSD:', formattedPriceInUSD); // Debug log
      setCurrentPrice(formattedPriceInNative);
      setPriceInUSD(formattedPriceInUSD);
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  };
  
  useEffect(() => {
    fetchCurrentPrice("1"); // Default to 1 token, or replace with a state variable if dynamic input is required
  }, []); // Empty dependency array ensures this effect only runs once after the component mounts
    
  
    
  const buyTokensWithNative = async (tokenAmountString) => {
    if (!isWalletConnected || !isCorrectNetwork) {
      setbuyError('Please connect to the wallet and ensure you are on the correct network.');
      return;
    }

    if (!signer) {
      console.error('Wallet is not connected');
      return;
    }
  
    try {
      const tokenAmount = ethers.BigNumber.from(tokenAmountString); 
      const [priceInNativeCoin] = await contract.getPrice(tokenAmount);
  
      console.log(`Price Per Token in Wei: ${priceInNativeCoin.toString()}`);
      console.log(`Token Amount: ${tokenAmountString}`);
  
      const totalCostInWei = priceInNativeCoin;
  
      console.log(`Total cost in Wei: ${totalCostInWei.toString()}`);
  
      const transactionResponse = await contract.connect(signer).buyWithNativeCoin(
        tokenAmount, 
        0, 
        {
          value: totalCostInWei.toString(), 
          gasLimit: ethers.utils.hexlify(200000)
        }
      );
  
      await transactionResponse.wait(); 
      console.log('Tokens bought successfully');
      fetchWalletBalance(); // Fetch wallet balance
    fetchTotalTokensSold(); // Fetch total tokens sold
    fetchTotalSoldPrice(); // Fetch total sold price
    fetchPurchasedTokens();

    } catch (error) {
      console.error('Error buying tokens:', error);
    }
  };
  // Function to fetch the price shift
  const fetchPriceShift = async () => {
    try {
      const shiftValueRaw = await contract.priceShift();
      // Convert shiftValueRaw to a BigNumber
      const priceShiftValue = ethers.BigNumber.from(shiftValueRaw);
      // Format the shift value with 6 decimal places (adjust as needed)
      const formattedShiftValue = ethers.utils.formatUnits(priceShiftValue, 18);
      setPriceShift(formattedShiftValue);
    } catch (error) {
      console.error('Error fetching price shift:', error);
    }
  };
  useEffect(() => {
    fetchCurrentPrice("1");
    fetchPriceShift();
  }, []);

  const fetchPresaleAmount = async () => {
    try {
      const result = await contract.presaleAmount();
      const presaleAmount = parseInt(result.toString());
      setPresaleAmount(presaleAmount);
    } catch (error) {
      console.error('Error fetching presale amount:', error);
    }
  };

  // Call fetchPresaleAmount when needed, such as when the component mounts
  useEffect(() => {
    fetchPresaleAmount();
  }, []);

    // Function to fetch the totalTokensSold value
    const fetchTotalTokensSold = async () => {
      try {
        const result = await contract.totalTokensSold();
        const tokensSold = parseInt(result.toString());
        setTotalTokensSold(tokensSold);
      } catch (error) {
        console.error('Error fetching totalTokensSold:', error);
      }
    };
  
    // Call fetchTotalTokensSold when needed, such as when the component mounts
    useEffect(() => {
      fetchTotalTokensSold();
    }, []);

    // Function to fetch the totalSoldPrice value
  const fetchTotalSoldPrice = async () => {
    try {
      const result = await contract.totalSoldPrice();
      const soldPrice = ethers.utils.formatUnits(result, 18); // Format to 6 decimal places
      setTotalSoldPrice(soldPrice);
    } catch (error) {
      console.error('Error fetching totalSoldPrice:', error);
    }
  };

  // Call fetchTotalSoldPrice when needed, such as when the component mounts
  useEffect(() => {
    fetchTotalSoldPrice();
  }, []);

  const fetchSaleStartTime = async () => {
    try {
        const startTime = await contract.saleStartTime(); // Accessing the public variable
        setSaleStartTime(Number(startTime)); // Convert BigNumber to a JavaScript number
        fetchDaysPast();
    } catch (error) {
        console.error('Error fetching sale start time:', error);
    }
};

useEffect(() => {
    fetchSaleStartTime();
}, []);

const fetchDaysPast = async () => {
  try {
    const daysPastFromContract = await contract.getDaysPast();
    setDaysPast(daysPastFromContract.toNumber());
    // ... any other logic
  } catch (error) {
    console.error('Error fetching days past:', error);
  }
};
const calculateRemainingTime = (daysPast) => {
  const currentTime = new Date();
  const saleStartDate = new Date(saleStartTime * 1000); // Convert to milliseconds
  let nextShiftTime = new Date(saleStartDate.getTime() + daysPast * 24 * 60 * 60 * 1000);

  // Adjust nextShiftTime if it's in the past
  while (nextShiftTime < currentTime) {
      nextShiftTime = new Date(nextShiftTime.getTime() + 24 * 60 * 60 * 1000);
  }

  const timeDiff = nextShiftTime - currentTime;

  // Convert timeDiff to a readable format
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

  setRemainingTime(`${hours} Hours ${minutes} Minutes`);
};
useEffect(() => {
  let timerInterval;
const calculateCountdown = () => {
  const currentTime = new Date();
  const saleStartDate = new Date(saleStartTime * 1000);
  let nextShiftTime = new Date(saleStartDate.getTime() + (daysPast + 1) * 24 * 60 * 60 * 1000);

  while (nextShiftTime < currentTime) {
    nextShiftTime = new Date(nextShiftTime.getTime() + 24 * 60 * 60 * 1000);
  }

  const timeDiff = nextShiftTime - currentTime;

  if (timeDiff <= 0) {
    clearInterval(timerInterval);
    setCountdown('00:00:00');
    return;
  }

  // Format the time difference
  const hours = String(Math.floor(timeDiff / (1000 * 60 * 60))).padStart(2, '0');
  const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
  const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');

  setCountdown(`${hours}:${minutes}:${seconds}`);
};

timerInterval = setInterval(calculateCountdown, 1000); // Set the interval

  return () => clearInterval(timerInterval); // Clear interval on component unmount
}, [saleStartTime, daysPast]); // Include dependencies

const fetchPurchasedTokens = async () => {
  if (signer && currentUserAddress) {
    try {
      const tokens = await contract.purchasedTokens(currentUserAddress);
      setPurchasedTokens(tokens.toNumber());
      console.log('Purchased Tokens:', tokens.toNumber());
    } catch (error) {
      console.error('Error fetching purchased tokens:', error);
    }
  }
};

useEffect(() => {
  // Call fetchPurchasedTokens when the component mounts or when the currentUserAddress changes
  fetchPurchasedTokens();
}, [currentUserAddress]);

// Function to fetch the claim start time from the contract
const fetchClaimStartTime = async () => {
  try {
    const startTime = await contract.claimStartTime();
    setClaimStartTime(Number(startTime)); // Convert BigNumber to a JavaScript number
  } catch (error) {
    console.error('Error fetching claim start time:', error);
  }
};

useEffect(() => {
  fetchClaimStartTime();
}, []);

// Function to check if the claim button should be locked
const checkClaimButtonLock = () => {
  const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

  // Check if the current time is greater than or equal to the claim start time
  if (currentTime >= claimStartTime) {
    setIsClaimButtonLocked(false); // Unlock the claim button
  } else {
    setIsClaimButtonLocked(true); // Lock the claim button
  }
};

useEffect(() => {
  checkClaimButtonLock();
}, [claimStartTime]); // Recheck when claimStartTime changes

const claimTokens = async () => {
  if (!signer) {
    console.error('Wallet is not connected');
    return;
  }

  // Check if the claim button is locked (claim start time hasn't passed)
  if (isClaimButtonLocked) {
    console.error('Claiming is not available at this time');
    return;
  }

  // Proceed with claiming tokens
  try {
    const gasLimit = 200000; // Set an appropriate gas limit for your contract call
    const transactionResponse = await contract.connect(signer).claim({
      gasLimit: ethers.utils.hexlify(gasLimit),
    });
    await transactionResponse.wait();
    console.log('Tokens claimed successfully');
    // Optionally, you can fetch updated data or perform other actions after claiming tokens.
  } catch (error) {
    console.error('Error claiming tokens:', error);
  }
};

useEffect(() => {
  const fetchCurrentUserAddress = async () => {
    if (signer) {
      const address = await signer.getAddress();
      setCurrentUserAddress(address);
      setIsWalletConnected(true); // Set isWalletConnected to true
      // ... rest of the existing code
    }
  };

  fetchCurrentUserAddress();
}, [signer]);

// Function to handle wallet connect
const handleWalletConnect = async () => {
  await open();
  await checkAndSwitchNetwork();
  // ...rest of your connection logic
};

//change
const desiredNetwork = {
  chainId: '0x38', // Hexadecimal chain ID for BSC Mainnet (56 in decimal)
  chainName: 'Binance Smart Chain Mainnet',
  rpcUrls: [bscMainnet.rpcUrl],
  blockExplorerUrls: [bscMainnet.explorerUrl]
};

const checkNetwork = async () => {
  if (signer) {
    try {
      const network = await signer.provider.getNetwork();
      setIsCorrectNetwork(network.chainId === bscMainnet.chainId);
      
    } catch (error) {
      console.error('Error checking network:', error);
      setIsCorrectNetwork(false);
    }
  }
};


useEffect(() => {
  checkNetwork();

  const handleNetworkChange = () => {
    checkNetwork();
  };

  // Subscribe to network change events
  // This part is specifically for MetaMask and similar wallets
  if (window.ethereum) {
    window.ethereum.on('chainChanged', handleNetworkChange);

    return () => {
      window.ethereum.removeListener('chainChanged', handleNetworkChange);
    };
  }
  // Note: For wallets like WalletConnect, you'll need to handle this differently
}, [signer]); // Add 'signer' as a dependency if it's defined in your component's state


const checkAndSwitchNetwork = async () => {
  if (window.ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();

      if (network.chainId !== parseInt(desiredNetwork.chainId, 16)) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: desiredNetwork.chainId }]
          });

          // Refresh the page after successfully switching the network
          window.location.reload();
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [desiredNetwork]
              });

              // Consider refreshing the page after adding the new network, if necessary
              // window.location.reload();
            } catch (addError) {
              // Handle errors when adding a new network
              console.error(addError);
            }
          } else {
            console.error(switchError);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    // Handle the case where the user doesn't have MetaMask installed
    console.error("Ethereum object doesn't exist!");
    
  }
};

if (!isWalletConnected) {
  // Show only the connect wallet button if not connected
  return (
    <div className="connect-wallet-center card-container">
         

<div className='card'>

<div className='remaining-time'>
<div className='time-label'>NEXT PRICE INCREASE IN</div>
<div className='timer'>
<div className='time-unit'>
  <span className='number'>{countdown.split(':')[0]}</span>
  <span className='unit'>Hours</span>
</div>
<div className='time-unit'>
  <span className='number'>{countdown.split(':')[1]}</span>
  <span className='unit'>Minutes</span>
</div>
<div className='time-unit'>
  <span className='number'>{countdown.split(':')[2]}</span>
  <span className='unit'>Seconds</span>
</div>
</div>



<div className='price-container'>
<div>
<div className='price-label'>Current Price</div>
<div className='price-box current-price'>
  1 XT = {Number(priceInUSD).toFixed(6)} USDT
</div>
</div>
<div>
<div className='price-label'>Next Price</div>
<div className='price-box next-price-in-usd'>
  1 XT = {Number(nextPrice).toFixed(6)} USDT
</div>
</div>
</div>

<div className='remaining-time'>
<div className='time-label'>PRESALE INFORMATION</div>
<div className='Amount'>
<div className='time-unit'>
  <span className='number'>{presaleAmount}</span>
  <span className='unit'>Presale Amount</span>
</div>
<div className='time-unit'>
  <span className='number'>{totalTokensSold}</span>
  <span className='unit'> Total Tokens Sold</span>
</div>
</div>
{/* Display the sell bar */}
<div className='sell-bar'>
    <div className='sell-progress' style={{ width: `${sellPercentage}%` }}>
      {sellPercentage.toFixed(5)}%
    </div>
  </div>
  {/* Display the fetched totalSoldPrice value */}
 <div className='total-sold-price-label'>
 USD Raised: 
  </div>
  <div className='total-sold-price'>
{totalSoldPrice} USD
  </div>
</div>


      <button className="connect-wallet-button" onClick={handleWalletConnect}>
        Connect Wallet
      </button>
      
    </div>
    </div>
    </div>

  );
}


if (typeof window.ethereum !== 'undefined' && !isCorrectNetwork) {
  // MetaMask or a similar extension is installed, but not connected to the correct network
  return (
    <div className="network-warning">
      <p>Please connect to the BSC Network.</p>
      <button onClick={checkAndSwitchNetwork}>Switch Network</button>
    </div>
  );
}


// Rest of your application

  return (
    
    
    <div className='shimmer-effect'>
   
    <div className="card-container">

    <div className='card'>

    <div className='remaining-time'>
  <div className='time-label'>PRÓXIMO AUMENTO DE PRECIO EN</div>
  <div className='timer'>
    <div className='time-unit'>
      <span className='number'>{countdown.split(':')[0]}</span>
      <span className='unit'>Horas</span>
    </div>
    <div className='time-unit'>
      <span className='number'>{countdown.split(':')[1]}</span>
      <span className='unit'>Minutos</span>
    </div>
    <div className='time-unit'>
      <span className='number'>{countdown.split(':')[2]}</span>
      <span className='unit'>Segundos</span>
    </div>
  </div>
</div>

  

<div className='price-container'>
  <div>
    <div className='price-label'>Current Price</div>
    <div className='price-box current-price'>
      1 BPD = {Number(priceInUSD).toFixed(14)} USDT
    </div>
  </div>
  <div>
    <div className='price-label'>Next Price</div>
    <div className='price-box next-price-in-usd'>
      1 BPD = {Number(nextPrice).toFixed(14)} USDT
    </div>
  </div>
</div>

<div className='remaining-time'>
  <div className='time-label'> INFORMACIÓN</div>
  <div className='info'>
    <div className='time-unit'>
      <span className='number'>{presaleAmount}</span>
      <span className='unit'>Monto de preventa</span>
    </div>
    <div className='time-unit'>
      <span className='number'>{totalTokensSold}</span>
      <span className='unit'>Total de tokens vendidos</span>
    </div>
  </div>
 {/* Display the sell bar */}
 <div className='sell-bar'>
        <div className='sell-progress' style={{ width: `${sellPercentage}%` }}>
          {sellPercentage.toFixed(5)}%
        </div>
      </div>
      {/* Display the fetched totalSoldPrice value */}
      
     <div className='total-sold-price-label'>
     USD recaudados: 
      </div>
      <div className='total-sold-price'>
   {totalSoldPrice} USD
      </div>
</div>


     

     

{/* Add a form to handle token purchase */}
<div className='purchase-form'>
    <input
      type="text"
      placeholder="Cantidad de tokens a comprar"
      value={purchaseAmount}
      onChange={(e) => setPurchaseAmount(e.target.value)}
    />
    <button onClick={() => buyTokensWithNative(purchaseAmount)}>
    Comprar Tokens
    </button>
</div>
<div className='estimate-cost'> <p>Costo Estimado: {estimatedCostInUSD} USD</p> </div>
{buyError && <p className="error-message">{buyError}</p>}

{/* UI for setting presale amount */}
<div className='div-purchased-tokens'>
      {/* Display the fetched purchased tokens to the user */}
      <p>Tokens Comprados: {purchasedTokens}</p>
      {/* ... other JSX content ... */}
    </div>

    <div className='div-claim-button'>
    <button
          className="claim-button"
          onClick={() => claimTokens()}
          disabled={isClaimButtonLocked} // Disable the button based on isClaimButtonLocked
        >
          Reclamar Tokens
        </button>
      </div>

      <div className='claim-start-date'>
      Fecha de inicio del reclamo:{new Date(claimStartTime * 1000).toLocaleDateString()}
</div>
</div>

</div>



    </div>
  );
}

