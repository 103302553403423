import React, { useState, useEffect } from 'react';
import './HoneypotInfo.css'; // Make sure to import the CSS file

const HoneypotInfo = ({ tokenAddress }) => {
    const [isHoneypot, setIsHoneypot] = useState(null);
    const [sellTax, setSellTax] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            // Simulating an API call
            const response = await fetch(`https://api.honeypot.is/v2/IsHoneypot?address=${tokenAddress}`);
            const data = await response.json();
            
            if (response.ok) {
                setIsHoneypot(data.honeypotResult.isHoneypot);
                setSellTax(data.simulationResult.sellTax);
            } else {
                console.error('Failed to fetch data from Honeypot API');
            }
        };

        fetchData();
    }, [tokenAddress]);

    const showHoneypotAlert = isHoneypot || (sellTax !== null && sellTax > 49);

    return (
        <>
            {showHoneypotAlert && (
                <div className="honeypot-container">
                    <div className="honeypot-alert">
                        <h3 className="honeypot-header">Honeypot Alert</h3>
                        <p className="honeypot-text">{isHoneypot ? 'This is a honeypot.' : 'High sell tax detected.'}</p>
                        {sellTax !== null && (
                            <div className="sell-tax">
                                <h3 className="sell-tax-header">Sell Tax</h3>
                                <p className="sell-tax-text">{sellTax}%</p> {/* Assuming sell tax is a percentage */}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default HoneypotInfo;
