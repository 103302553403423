import React, { useState, useEffect } from 'react';
import loadingGift from './imagen.gif'; // Adjust the path as necessary
import successGift from './success1.gif'; // Adjust the path as necessary
import errorGift from './fail.png'; // Adjust the path as necessary
import './TransactionModal.css'; // Assuming you have a CSS file for modal styles

const TransactionModal = ({ isVisible, message, onClose }) => {
    const [imageSrc, setImageSrc] = useState(loadingGift);

    useEffect(() => {
        // Assuming 'message' will be updated based on the transaction status
        switch(message) {
            case 'Transaction completed successfully!':
                setImageSrc(successGift);
                break;
            case 'The swap failed due to an unexpected error. Please try again.':
            case 'Swap failed: The output amount is too low. Try adjusting the amount or slippage tolerance.':
            case 'Swap failed: Cannot estimate gas; the transaction may fail or may require manual gas limit.':
            case 'Swap failed: Transaction signature was denied by the user.':
            case 'Swap failed: Insufficient funds for transaction fee. Your account does not have enough Ether for gas.':
                setImageSrc(errorGift);
                break;
            default:
                setImageSrc(loadingGift); // Default or loading state
        }
    }, [message]);
  
    if (!isVisible) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content-transaction">
          <img src={imageSrc} alt="Transaction Status" style={{ margin: '20px auto', display: 'block', maxWidth: '100px' }} />
          <h3>Transaction Status</h3>
          <p>{message}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
};

export default TransactionModal;