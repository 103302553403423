import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { abi as DeflationarySwapABI } from './DeflationarySwapABI';
import './swap.css'; // Assuming you have a CSS file for modal styles
import { FaCopy } from 'react-icons/fa'; // Importing the copy icon
import { FiRefreshCw } from 'react-icons/fi';
import ConnectButton from '../App'
import Notification from './Notification' // Adjust the path as necessary if your file structure is different
import TokenBalances from './TokenBalances';
import WalletSelectorModal from './WalletSelectorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

import logoImage from './logos/AIBOT_logo.png';
import default_logo from './logos/defaul_logo.png';
import BTC_logo from './logos/btclogo.png';
import BNB_logo from './logos//bnblogo.png';
import USDT_logo from './logos/usdt.png';
import metamask_logo from './logos/metamask.png';

function WalletDisplay({ signer, walletBalance, onSwapComplete, isSidebarVisible }) {
  const [showModal, setShowModal] = useState(false);
  
    const handleOpenModal = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleSelectToken = (selectedAddress) => {
      setAddress(selectedAddress);
      handleCloseModal();
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            // This is where you might show a notification or toast to the user indicating success
            console.log('Address copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      };

      const addToMetaMask = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage) => {
        try {
          if (window.ethereum && window.ethereum.isMetaMask) {
            // Check if MetaMask is connected to the Binance Smart Chain
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId !== '0x38') { // 0x38 is the chainId for BSC
              alert('Please switch to the Binance Smart Chain network in your MetaMask wallet.');
              return;
            }
      
            const wasAdded = await window.ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: tokenAddress,
                  symbol: tokenSymbol,
                  decimals: tokenDecimals,
                  image: tokenImage,
                },
              },
            });
      
            if (wasAdded) {
              console.log('Token has been added to your MetaMask wallet.');
            } else {
              console.log('Token addition was rejected.');
            }
          } else {
            alert('MetaMask is not installed. Please install MetaMask and try again.');
          }
        } catch (error) {
          console.error('Error adding token to MetaMask', error);
        }
      };
      
      
  
    return (
        <div className="form-control">
          <div className='token-info-logo'>
            
          </div>

         
      
         

            <div className='token-info-contract'>
            <button onClick={handleOpenModal} className="wallet-button">
      <FontAwesomeIcon icon={faWallet} /> {" "}
    </button>
      </div>
      <WalletSelectorModal
    isOpen={showModal}
    onClose={handleCloseModal}
    onSelectToken={handleSelectToken}
    signer={signer} // Passing the signer prop
/>


        </div>
      );
      
  };

  export default WalletDisplay;
