import React, { useState, useEffect } from 'react';
import Moralis from 'moralis';
import { ethers } from 'ethers'; // Import ethers
import '../Swap/swap.css'; // Assuming you have a CSS file for modal styles

const TokenBalances = ({ userAddress }) => {
  const [tokens, setTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTokenBalances = async () => {
    setIsLoading(true);
    try {
      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        chain: "0x38",
        address: userAddress,
      });
      setTokens(response.raw);
    } catch (error) {
      console.error("Moralis API call failed:", error);
      // Attempt to fetch from the secondary API upon Moralis failure
      try {
        // Hypothetical method call, replace with actual call to your secondary API
        const response = await SecondaryAPI.getWalletTokenBalances({
          chain: "0x38",
          address: userAddress,
        });
        setTokens(response.raw); // Assume the secondary API has a similar response structure
      } catch (secondaryError) {
        console.error("Secondary API call failed:", secondaryError);
        setTokens([]); // Handle failure from both Moralis and secondary API
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userAddress) {
      fetchTokenBalances();
    }
  }, [userAddress]);

  const formatBalance = (balance, decimals) => {
    const formattedBalance = ethers.utils.formatUnits(balance, decimals);
    return Number(formattedBalance).toFixed(4);
  };

  return (
    <div className='wallet-balances'>
      <div className='token-balances-container'>
        <h2 className='token-balances-header'>Token Balances</h2>
        <table className='token-table'>
          <thead>
            <tr>
              <th>TOKEN</th>
              <th>BALANCE</th>
            </tr>
          </thead>
          <tbody>
            {tokens.map(token => (
              <tr key={token.token_address}>
                <td>{token.name} ({token.symbol})</td>
                <td>{formatBalance(token.balance, token.decimals)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TokenBalances;