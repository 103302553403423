import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { abi as DeflationarySwapABI } from './DeflationarySwapABI';
import '../Swap/swap.css'; // Assuming you have a CSS file for modal styles
import { FaCopy } from 'react-icons/fa'; // Importing the copy icon
import { FiRefreshCw } from 'react-icons/fi';
import ConnectButton from '../App'
import Notification from '../Swap/Notification' // Adjust the path as necessary if your file structure is different
import TokenBalances from './TokenBalances';
import TokenDisplay from './TokenDisplay'
import WalletDisplay from './WalletDisplay'
import Moralis from 'moralis';
import TransactionModal from './TransactionModal';



import logoImage from './logos/AIBOT_logo.png';
import default_logo from './logos/defaul_logo.png';
import BTC_logo from './logos/btclogo.png';
import BNB_logo from './logos//bnblogo.png';
import USDT_logo from './logos/usdt.png';
import metamask_logo from './logos/metamask.png';
import TokenSelectorModal from './TokenSelectorModal';




const tokenAbi = [
    // approve function
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    // Add the allowance function definition
    {
        "constant": true,
        "inputs": [
            {
                "name": "owner",
                "type": "address"
            },
            {
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },

    {
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]; 

const SwapComponent = ({ signer, walletBalance, onSwapComplete, tokenIn, tokenOut , onClose }) => {
    const contractAddress = "0x09530B6F5682a98D34944e245C4c1FCb12066f65";
    const [swapType, setSwapType] = useState('swapExactTokensForTokensSupportingFeeOnTransferTokens');
    const [amountIn, setAmountIn] = useState('0');
    const [amountOut, setAmountOut] = useState('0');
    const [amountInMax, setAmountInMax] = useState('');
    const [amountOutMin, setAmountOutMin] = useState('');
    const defaultTokenInAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // Replace with your actual default IN token address
    const defaultTokenOutAddress = "0xaE4c637fb9cb5C151549768a787CCa54c044BdcA"; // Replace with your actual default OUT token address
    const [tokenInAddress, setTokenInAddress] = useState(tokenIn || defaultTokenInAddress);
const [tokenOutAddress, setTokenOutAddress] = useState(tokenOut || defaultTokenOutAddress);
    const [slippageTolerance, setSlippageTolerance] = useState('7');
    const [tokenInPrice, setTokenInPrice] = useState(null);
    const [tokenOutPrice, setTokenOutPrice] = useState(null);
    const [lastEdited, setLastEdited] = useState(null); // Track which input was last edited
    const WRAPPED_ETH_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    const [tokenInInfo, setTokenInInfo] = useState(null);
    const [tokenOutInfo, setTokenOutInfo] = useState(null);
    const [poolAddress, setPoolAddress] = useState('');
    const [embedUrl, setEmbedUrl] = useState('');
    const [isUsingTokenInAddress, setIsUsingTokenInAddress] = useState(false); // true for tokenInAddress, false for tokenOutAddress
    const [tokenInPoolAddress, setTokenInPoolAddress] = useState('');
    const [tokenOutPoolAddress, setTokenOutPoolAddress] = useState('');
    const [tokenInBalance, setTokenInBalance] = useState('0');
    const [tokenOutBalance, setTokenOutBalance] = useState('0');
    const [amountInUSDValue, setAmountInUSDValue] = useState('0');
    const [amountOutUSDValue, setAmountOutUSDValue] = useState('0');
    const [notifications, setNotifications] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    const [calculatedAmountOut, setCalculatedAmountOut] = useState(0);
    const [calculatedAmountOutUSD, setCalculatedAmountOutUSD] = useState(0);
    const [reserve0, setReserve0] = useState('0');
    const [reserve1, setReserve1] = useState('0');
    const [pairAddress, setPairAddress] = useState('');
    const [error, setError] = useState(''); // State to hold error messages
    const [priceImpact, setPriceImpact] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);

    const toggleFormVisibility = () => {
      setIsFormVisible(!isFormVisible);
    };

    useEffect(() => {
      const calculatePriceImpact = () => {
        // Check if token information and amountIn are available and valid
        if (!tokenInInfo || !amountIn || isNaN(amountIn) || parseFloat(amountIn) <= 0) {
          console.error("Invalid token info or amountIn value:", { tokenInInfo, amountIn });
          setPriceImpact('0'); // Reset price impact to 0 or some default value
          return; // Exit the function if validation fails
        }
    
        const tokenInDecimals = tokenInInfo ? tokenInInfo.data.attributes.decimals : 18; // Fallback to 18 if not available
        
        // Ensure amountIn is a valid number before parsing
        let amountInPrice;
        try {
          amountInPrice = ethers.utils.parseUnits(amountIn.toString(), tokenInDecimals);
        } catch (error) {
          console.error("Error parsing amountIn to BigNumber:", error);
          setPriceImpact('0'); // Reset price impact to 0 or handle the error appropriately
          return; // Exit the function if parsing fails
        }
    
        const reserveIn = swapType === 'swapExactETHForTokensSupportingFeeOnTransferTokens' ? reserve1 : reserve0;
        const reserveOut = swapType === 'swapExactETHForTokensSupportingFeeOnTransferTokens' ? reserve0 : reserve1;
    
        // Prevent division by zero or negative impacts
        if (!reserveIn || !reserveOut || parseFloat(reserveIn) <= 0 || parseFloat(reserveOut) <= 0) {
          console.error("Invalid reserves:", { reserveIn, reserveOut });
          setPriceImpact('0'); // Reset price impact to 0 or some default value
          return; // Exit the function if reserves are invalid
        }
    
        const priceImpact = (parseFloat(amountInPrice) / (parseFloat(amountInPrice) + parseFloat(reserveIn))) * 110;
        setPriceImpact(priceImpact.toFixed(2));
      };
    
      calculatePriceImpact();
    }, [tokenInInfo, amountIn, reserve0, reserve1, swapType]); // Dependency array to trigger effect on change
    
    useEffect(() => {
      const fetchPairAddress = async () => {
        setError(''); // Reset error state before new API call
        try {
          let response;
          if (swapType === 'swapExactETHForTokensSupportingFeeOnTransferTokens') {
            response = await Moralis.EvmApi.defi.getPairAddress({
              chain: "0x38",
              exchange: "pancakeswapv2",
              token0Address: tokenOutAddress,
              token1Address: tokenInAddress
            });
          } else if (swapType === 'swapExactTokensForETHSupportingFeeOnTransferTokens') {
            response = await Moralis.EvmApi.defi.getPairAddress({
              chain: "0x38",
              exchange: "pancakeswapv2",
              token0Address: tokenInAddress,
              token1Address: tokenOutAddress
            });
          } else { // Assuming swapType is 'swapExactTokensForTokensSupportingFeeOnTransferTokens' or any other value
            response = await Moralis.EvmApi.defi.getPairAddress({
              chain: "0x38",
              exchange: "pancakeswapv2",
              token0Address: tokenOutAddress,
              token1Address: tokenInAddress
            });
          }
  
          if (response && response.raw && response.raw.pairAddress) {
            setPairAddress(response.raw.pairAddress);
          } else {
            throw new Error("No pair address found.");
          }
        } catch (error) {
          console.error(error);
          setError('Failed to fetch pair address. Please check the console for more details.');
        }
      };
  
      if (tokenInAddress && tokenOutAddress) {
        fetchPairAddress();
      }
    }, [swapType, tokenInAddress, tokenOutAddress]);
  
    
    useEffect(() => {
        const fetchUserAddress = async () => {
            if (signer) {
                const address = await signer.getAddress();
                setUserAddress(address);
            }
        };

        fetchUserAddress();
    }, [signer]); 
    
    useEffect(() => {
  fetchTokenInfo(tokenInAddress)
    .then(info => {
      setTokenInInfo(info);
    })
    .catch(error => console.error("Failed to fetch token in info:", error));
}, [tokenInAddress]); // This will run on mount and whenever tokenInAddress changes

useEffect(() => {
  fetchTokenInfo(tokenOutAddress)
    .then(info => {
      setTokenOutInfo(info);
    })
    .catch(error => console.error("Failed to fetch token out info:", error));
}, [tokenOutAddress]); // This will run on mount and whenever tokenOutAddress changes


    useEffect(() => {
        if (tokenInAddress === WRAPPED_ETH_ADDRESS) {
            setSwapType('swapExactETHForTokensSupportingFeeOnTransferTokens');
        } else if (tokenOutAddress === WRAPPED_ETH_ADDRESS) {
            setSwapType('swapExactTokensForETHSupportingFeeOnTransferTokens');
        } else {
            setSwapType('swapExactTokensForTokensSupportingFeeOnTransferTokens');
        }
    }, [tokenInAddress, tokenOutAddress]);

     // Function to fetch token price from GeckoTerminal API
async function fetchTokenPriceFromGecko(tokenAddress) {
  try {
      const response = await fetch(`https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/${tokenAddress}`, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error(`Error fetching price for address ${tokenAddress}`);
      }

      const data = await response.json();
      return data.data.attributes.token_prices[tokenAddress.toLowerCase()];
  } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch price from GeckoTerminal');
  }
}

// Function to fetch token price from Moralis API
async function fetchTokenPriceFromMoralis(tokenAddress) {
  try {
     

      const response = await Moralis.EvmApi.token.getTokenPrice({
          chain: "0x38", // BSC Chain ID is 0x38 in hex
          address: tokenAddress,
          // Optionally include other parameters
      });

      if (response && response.raw) {
          console.log(response.raw);
          return response.raw.usdPrice; // Adjust this based on the data you need
      } else {
          throw new Error('Moralis response was empty or missing data');
      }
  } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch price from Moralis');
  }
}

// Function to orchestrate the fallback logic
async function fetchTokenPrice(tokenAddress) {
  try {
      return await fetchTokenPriceFromGecko(tokenAddress);
  } catch (error) {
      console.error("GeckoTerminal API failed, trying Moralis...", error);
      return await fetchTokenPriceFromMoralis(tokenAddress);
  }
}

    

    // useEffect hook to fetch prices whenever token addresses change
    useEffect(() => {
        if (tokenInAddress) {
            fetchTokenPrice(tokenInAddress)
                .then(price => setTokenInPrice(price))
                .catch(error => console.error(error));
        }
    }, [tokenInAddress]);

    useEffect(() => {
        if (tokenOutAddress) {
            fetchTokenPrice(tokenOutAddress)
                .then(price => setTokenOutPrice(price))
                .catch(error => console.error(error));
        }
    }, [tokenOutAddress]);

    const pairPrice = tokenInPrice && tokenOutPrice ? parseFloat(tokenInPrice) / parseFloat(tokenOutPrice) : null;

    useEffect(() => {
        if (lastEdited === 'in' && pairPrice) {
            const amountOutCalculated = parseFloat(amountIn) * pairPrice;
            setAmountOut(amountOutCalculated.toFixed(6).toString()); // Keep 6 decimal places, adjust as needed
        }
    }, [amountIn, pairPrice, lastEdited]);

    useEffect(() => {
        if (lastEdited === 'out' && pairPrice) {
            const amountInCalculated = parseFloat(amountOut) / pairPrice;
            setAmountIn(amountInCalculated.toFixed(6).toString()); // Keep 6 decimal places, adjust as needed
        }
    }, [amountOut, pairPrice, lastEdited]);

    const calculateAmountOutMin = () => {
        if (!slippageTolerance || !amountOut || isNaN(parseFloat(slippageTolerance)) || parseFloat(slippageTolerance) <= 0) {
            return;
        }
    
        // Convert the slippage tolerance from percentage to a multiplier
        const slippageMultiplier = (100 - parseFloat(slippageTolerance)) / 100;
    
        // Calculate the minimum amount out considering the slippage tolerance
        const minAmountOut = parseFloat(amountOut) * slippageMultiplier;
    
        // Update the state
        setAmountOutMin(minAmountOut.toString());
    };
    
    useEffect(() => {
        if (!slippageTolerance || isNaN(parseFloat(slippageTolerance)) || parseFloat(slippageTolerance) <= 0) {
            // Set some default or leave the field blank if slippage tolerance is not valid
            setAmountOutMin('');
            return;
        }
    
        if (amountIn && !isNaN(parseFloat(amountIn)) && parseFloat(amountIn) > 0) {
            // Example calculation for amountOutMin based on amountIn
            // This assumes a direct conversion and might need adjustment based on your actual swap logic
            const slippageMultiplier = 1 - parseFloat(slippageTolerance) / 100;
            const calculatedAmountOutMin = parseFloat(amountOut) * slippageMultiplier; // Simplified example
            setAmountOutMin(calculatedAmountOutMin.toString());
        }
    }, [amountIn, slippageTolerance]);

    const updateCalculations = () => {
        // Assuming this calculates amountOutMin based on the latest inputs
        // You might need to adjust it to fit your logic for calculating amountIn as well
        if (slippageTolerance && amountOut && !isNaN(parseFloat(slippageTolerance)) && parseFloat(slippageTolerance) > 0) {
            const slippageMultiplier = (100 - parseFloat(slippageTolerance)) / 100;
            const minAmountOut = parseFloat(amountOut) * slippageMultiplier;
            setAmountOutMin(minAmountOut.toString());
        }
    };

    const fetchBNBBalance = async (userAddress) => {
        if (!signer || !userAddress) {
          console.error('Signer or user address is not available.');
          return;
        }
      
        try {
          const balanceRaw = await signer.getBalance(userAddress);
          const balanceFormatted = ethers.utils.formatEther(balanceRaw); // Converts Wei to Ether (BNB in this case)
          return balanceFormatted;
        } catch (error) {
          console.error('Error fetching BNB balance:', error);
        }
      };
      

    const handleSwap = async () => {
      setIsModalVisible(true); // Show the modal
      console.log("Modal Visible:", isModalVisible);
console.log("Modal Message:", modalMessage);

  setModalMessage('Approving tokens...');
        if (!signer) {
            addNotification('Signer not found. Please connect your wallet to proceed.');
            return;
        }
        updateCalculations();
        const toAddress = await signer.getAddress();
        const contract = new ethers.Contract(contractAddress, DeflationarySwapABI, signer);
        const path = [tokenInAddress.trim(), tokenOutAddress.trim()];
        const deadline = Math.floor(Date.now() / 1000) + (20 * 60); // 20 minutes from now
    
        // Approval logic for ERC20 token swaps
        if (!swapType.includes('ETHFor')) { // Check if the swap involves ERC20 tokens
            const tokenContract = new ethers.Contract(tokenInAddress, tokenAbi, signer);
            const swapContractAddress = contractAddress; // Swap contract's address
            const amountInParsed = ethers.utils.parseUnits(amountIn, 'ether');
            
            // Check the current allowance
            const allowance = await tokenContract.allowance(toAddress, swapContractAddress);
            if (allowance.lt(amountInParsed)) {
                // If the allowance is insufficient, request approval
                console.log(`Approving ${swapContractAddress} to spend tokens...`);
                const approveTx = await tokenContract.approve(swapContractAddress, amountInParsed);
                await approveTx.wait(); // Wait for the approval transaction to be mined
                console.log('Approval successful');
            }
        }
        if (!signer) {
            addNotification('Signer not found. Please connect your wallet.');
            return;
        }
    
       // Ensure `amountIn` is valid before proceeding
       if (!amountIn || isNaN(parseFloat(amountIn)) || parseFloat(amountIn) <= 0) {
        addNotification('Invalid "Amount In" value. Please correct it before swapping.');
        return;
    }
    
    // Automatically calculate `amountOutMin` based on `amountOut` and `slippageTolerance`
    // This assumes `amountOut` is already set, perhaps from a previous automatic calculation or initial estimate
    if (!slippageTolerance || isNaN(parseFloat(slippageTolerance)) || parseFloat(slippageTolerance) <= 0) {
        addNotification('Slippage tolerance is not set or invalid. Please ensure it is a positive number.');
        return;
    }
    

    const slippageMultiplier = (100 - parseFloat(slippageTolerance)) / 100;

// Dynamic handling of token decimals
const tokenInDecimals = tokenInInfo ? tokenInInfo.data.attributes.decimals : 18; // Fallback to 18 if not available
const tokenOutDecimals = tokenOutInfo ? tokenOutInfo.data.attributes.decimals : 18; // Fallback to 18 if not available

// Assuming `amountOut` is the ideal output without slippage, calculate the minimum acceptable output
const slippageAdjustedAmountOut = parseFloat(amountOut) * slippageMultiplier;

// Ensure the calculated amountOutMin does not exceed the token's decimal precision
const amountOutMinFinal = ethers.utils.formatUnits(
  ethers.utils.parseUnits(slippageAdjustedAmountOut.toFixed(tokenOutDecimals), tokenOutDecimals),
  tokenOutDecimals
);

    
        try {
            // Your existing try block for performing swaps...
        } catch (error) {
            console.error('Swap failed:', error);
            alert('Swap failed: ' + error.message);
        }

        try {
            let tx;
            let amountInParsed, amountOutMinParsed;
            setModalMessage('Swapping...');

            switch (swapType) {
                case 'swapExactTokensForTokensSupportingFeeOnTransferTokens':
                    // Use the fetched decimals for tokenIn and tokenOut
                    amountInParsed = ethers.utils.parseUnits(amountIn, tokenInInfo ? tokenInInfo.data.attributes.decimals : '18');
                    amountOutMinParsed = ethers.utils.parseUnits(amountOutMin, tokenOutInfo ? tokenOutInfo.data.attributes.decimals : '18');
        
                    tx = await contract[swapType](
                        amountInParsed,
                        amountOutMinParsed,
                        path,
                        toAddress,
                        deadline
                    );
                    break;
        
                case 'swapExactETHForTokensSupportingFeeOnTransferTokens':
                    // When swapping ETH for tokens, ETH is assumed to have 18 decimals, but amountOutMin should use tokenOut decimals
                    amountOutMinParsed = ethers.utils.parseUnits(amountOutMin, tokenOutInfo ? tokenOutInfo.data.attributes.decimals : '18');
        
                    tx = await contract[swapType](
                        amountOutMinParsed,
                        path,
                        toAddress,
                        deadline,
                        { value: ethers.utils.parseUnits(amountIn, '18') } // ETH is assumed 18 decimals
                    );
                    break;
        
                case 'swapExactTokensForETHSupportingFeeOnTransferTokens':
                    // When swapping tokens for ETH, amountIn should use tokenIn decimals, ETH for amountOutMin is assumed 18 decimals
                    amountInParsed = ethers.utils.parseUnits(amountIn, tokenInInfo ? tokenInInfo.data.attributes.decimals : '18');
        
                    tx = await contract[swapType](
                        amountInParsed,
                        ethers.utils.parseUnits(amountOutMinFinal, '18'), // ETH assumed 18 decimals
                        path,
                        toAddress,
                        deadline
                    );
                    break;
        
                default:
                    alert('Invalid swap type.');
                    return;
            }
            await tx.wait();
            await fetchTokenBalances();
            setModalMessage('Transaction completed successfully!');
            addNotification('Swap successful!', 'success');
            await onSwapComplete(); // Directly using destructured prop

          } catch (error) {
            console.log(error);

            // Initialize a user-friendly error message
            let errorMessage = 'The swap failed due to an unexpected error. Please try again.';
        
            // Handle insufficient output amount
            if (error.message.includes('INSUFFICIENT_OUTPUT_AMOUNT')) {
                errorMessage = 'Swap failed: The output amount is too low. Try adjusting the amount or slippage tolerance.';
            } 
            // Handle unpredictable gas limit
            else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
                errorMessage = 'Swap failed: Cannot estimate gas; the transaction may fail or may require manual gas limit.';
            } 
            // Handle user denial of transaction signature
            else if (error.code === 4001) {
                errorMessage = 'Swap failed: Transaction signature was denied by the user.';
            } 
            // Handle insufficient funds for gas
            else if (error.code === -32603 && error.data && error.data.code === -32000 && /insufficient funds for gas \* price \+ value/.test(error.data.message)) {
                errorMessage = 'Swap failed: Insufficient funds for transaction fee. Your account does not have enough Ether for gas.';
            }
            // Add more conditions here based on other errors you want to handle
        
            // Display the error message to the user
            addNotification(errorMessage, 'error');
            setModalMessage(errorMessage);

        }
        

        

        
    };

    const addNotification = (message, type) => {
        // Create a unique ID for each notification (e.g., using a timestamp or a library like uuid)
        const id = new Date().getTime();
        setNotifications([...notifications, { id, message, type }]);
      };
      
      const removeNotification = (id) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
      };

   

    const handleTokensForETHSwap = async () => {
        if (!signer) {
            alert('Signer not found.');
            return;
        }

        const toAddress = await signer.getAddress(); // Your wallet address
        const deadline = Math.floor(Date.now() / 1000) + (20 * 60); // 20 minutes from now
        const path = [tokenInAddress.trim(), tokenOutAddress.trim()];


        // Instantiate the contract with ethers.js
        const contract = new ethers.Contract(contractAddress, DeflationarySwapABI, signer);

        // Token approval logic (similar to your existing setup)
        const tokenContract = new ethers.Contract(tokenInAddress, tokenAbi, signer);
        const amountInWei = ethers.utils.parseUnits(amountIn, 'ether'); // Convert amount to Wei
        const amountInEther = "0.0001"; // The original amount that caused the underflow

        // Check allowance
        const allowance = await tokenContract.allowance(toAddress, contractAddress);
        if (allowance.lt(amountInWei)) {
            console.log(`Approving ${contractAddress} to spend tokens...`);
            const approveTx = await tokenContract.approve(contractAddress, amountInWei);
            await approveTx.wait();
            console.log('Approval successful');
        }

        // Perform the swap
        try {
            const tx = await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
                amountInWei,
                ethers.utils.parseUnits(amountInEther, "18"),
                path,
                toAddress,
                deadline
            );
            await tx.wait();
            alert('Swap successful!');
        } catch (error) {
            console.error('Swap failed:', error);
            alert('Swap failed: ' + error.message);
        }

    };

    // Function to fetch token information from GeckoTerminal
    // Function to fetch token information from GeckoTerminal or CoinGecko with Moralis as a fallback
const fetchTokenInfo = async (tokenAddress) => {
  // Define WBNB address
  const WBNB_ADDRESS = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase();

  // Attempt to fetch from GeckoTerminal or CoinGecko first
  try {
      let response, data;
      if (tokenAddress.toLowerCase() === WBNB_ADDRESS) {
          response = await fetch(`https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=${tokenAddress}&vs_currencies=usd`);
          if (!response.ok) {
              throw new Error('Failed to fetch token price from CoinGecko');
          }
          data = await response.json();
          const price_usd = data[tokenAddress.toLowerCase()].usd;
          return {
              data: {
                  attributes: {
                      name: 'BNB',
                      symbol: 'BNB',
                      address: tokenAddress,
                      decimals: 18,
                      price_usd: price_usd.toString(),
                      image_url:BNB_logo, // Placeholder for BNB logo
                  }
              }
          };
      } else {
          response = await fetch(`https://api.geckoterminal.com/api/v2/networks/bsc/tokens/${tokenAddress}`, {
              method: 'GET',
              headers: { 'Accept': 'application/json' },
          });
          if (!response.ok) {
              throw new Error(`Error fetching token info for address ${tokenAddress}: ${response.statusText}`);
          }
          data = await response.json();
          return data;
      }
  } catch (error) {
      console.error('Primary source failed, trying Moralis...', error.message);
      // If primary source fails, attempt to fetch from Moralis
      return fetchTokenInfoFromMoralis(tokenAddress);
  }
};
async function fetchTokenInfoFromMoralis(tokenAddress) {
  try {
      const response = await Moralis.EvmApi.token.getTokenPrice({
          chain: "0x38", // Adjust for Ethereum; use "0x38" for BSC, etc.
          address: tokenAddress,
          include: "percent_change",
      });

      if (response && response.raw) {
          return {
              data: {
                  attributes: {
                      name: response.raw.tokenName,
                      symbol: response.raw.tokenSymbol,
                      address: response.raw.tokenAddress,
                      decimals: parseInt(response.raw.tokenDecimals, 10),
                      price_usd: response.raw.usdPrice.toString(),
                      price_usd_formatted: response.raw.usdPriceFormatted,
                      percent_change_24hr: response.raw["24hrPercentChange"],
                      image_url: response.raw.tokenLogo,
                      exchange_name: response.raw.exchangeName,
                      // Include any additional attributes as needed
                  }
              }
          };
      } else {
          throw new Error('Moralis response was empty or missing data');
      }
  } catch (error) {
      console.error('Failed to fetch data from Moralis', error);
      return null;
  }
}





    // Effect to fetch token in information
    useEffect(() => {
        {
            fetchTokenInfo(tokenInAddress)
                .then(info => {
                    setTokenInInfo(info);
                })
                .catch(error => console.error("Failed to fetch token in info:", error));
        }
    }, [tokenInAddress]); // Fetch token in info when tokenInAddress changes

    // Effect to fetch token out information
    useEffect(() => {
        {
            fetchTokenInfo(tokenOutAddress)
                .then(info => {
                    setTokenOutInfo(info);
                })
                .catch(error => console.error("Failed to fetch token out info:", error));
        }
    }, [tokenOutAddress]); // Fetch token out info when tokenOutAddress changes

    

useEffect(() => {
  // Directly use the pairAddress for operations if it exists
  if (pairAddress) {
    console.log("Using fetched pair address:", pairAddress);


    // Fetching reserves using the pairAddress
    const fetchReserves = async () => {
      try {
        const response = await Moralis.EvmApi.defi.getPairReserves({
          chain: "0x38", // BSC Mainnet chain ID
          pairAddress: pairAddress
        });

        console.log("Reserves:", response.raw);
        // Update state with fetched reserves
        setReserve0(response.raw.reserve0);
        setReserve1(response.raw.reserve1);
      } catch (e) {
        console.error("Failed to fetch pool reserves:", e);
      }
    };

    // Invoke the function to fetch reserves
    fetchReserves();
  }
}, [pairAddress]); // Depend on pairAddress

      
const handleAmountChange = (event, type) => {
  let value = event.target.value;

  // Allow the value to be empty and only validate if it has content
  value = (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) ? value : "0";

  if (type === 'in') {
      setAmountIn(value); // Reset to "0" only when updating state
      setLastEdited('in');
      
      // Only recalculate and set USD value for amount in if value is not empty
      const calculatedInUSDValue = value !== '' ? (parseFloat(value) * (tokenInPrice || 0)).toFixed(2) : "0.00";
      setAmountInUSDValue(calculatedInUSDValue);
  } else if (type === 'out') {
    setAmountOut(value);
    setLastEdited('out');
      
      // Only recalculate and set USD value for amount out if value is not empty
      const calculatedOutUSDValue = value !== '' ? (parseFloat(value) * (tokenOutPrice || 0)).toFixed(2) : "0.00";
      setAmountOutUSDValue(calculatedOutUSDValue);
  }
};

const handleBlur = (type) => {
  if (type === 'in' && amountIn === '') {
      setAmountIn("0");
  } else if (type === 'out' && amountOut === '') {
      setAmountOut("0");
  }
};



    useEffect(() => {
        // Recalculate amountInUSDValue if amountIn or tokenInPrice changes
        const calculatedInUSDValue = (parseFloat(amountIn) * tokenInPrice).toFixed(2);
        setAmountInUSDValue(calculatedInUSDValue);
    }, [amountIn, tokenInPrice]); // Depend on amountIn and tokenInPrice
    
    useEffect(() => {
        // Recalculate amountOutUSDValue if amountOut or tokenOutPrice changes
        const calculatedOutUSDValue = (parseFloat(amountOut) * tokenOutPrice).toFixed(2);
        setAmountOutUSDValue(calculatedOutUSDValue);
    }, [amountOut, tokenOutPrice]); // Depend on amountOut and tokenOutPrice
    

      const defaultAddresses = [
        { name: 'FAIB2', address: '0xaE4c637fb9cb5C151549768a787CCa54c044BdcA', logo: logoImage},
        { name: 'USDT', address: '0x55d398326f99059fF775485246999027B3197955', logo: USDT_logo},
        { name: 'BNB', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', logo: BNB_logo},
        { name: 'BTC', address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', logo: BTC_logo},

      ];
      
      const swapTokens = () => {
        // Temporarily store the current value of tokenInAddress
        const currentTokenInAddress = tokenInAddress;
      
        // Set tokenInAddress to the current value of tokenOutAddress
        setTokenInAddress(tokenOutAddress);
      
        // Set tokenOutAddress to the temporarily stored currentTokenInAddress
        setTokenOutAddress(currentTokenInAddress);
      };

      const formatBalance = (balanceRaw, decimals) => {
        // First, convert the raw balance to a full decimal string using the token's actual decimals
        const balanceFull = ethers.utils.formatUnits(balanceRaw, decimals);
        
        // Then, truncate to 5 decimals. One way to do this is to convert to a Number and use toFixed.
        // Note: toFixed returns a string, and it rounds the last digit.
        const balanceFiveDecimals = Number(balanceFull).toFixed(5);
        
        return balanceFiveDecimals;
      };

      
      
      const fetchTokenBalances = async () => {
        if (!signer || !tokenInInfo || !tokenOutInfo) {
          console.error('Signer or token info is not available.');
          return;
        }
      
        const userAddress = await signer.getAddress();
      
        // Fetch and format token in balance
        const tokenInContract = new ethers.Contract(tokenInAddress, tokenAbi, signer);
        const tokenInBalanceRaw = await tokenInContract.balanceOf(userAddress);
        const tokenInBalanceFormatted = formatBalance(tokenInBalanceRaw, tokenInInfo.data.attributes.decimals);
      
        // Fetch and format token out balance
        const tokenOutContract = new ethers.Contract(tokenOutAddress, tokenAbi, signer);
        const tokenOutBalanceRaw = await tokenOutContract.balanceOf(userAddress);
        const tokenOutBalanceFormatted = formatBalance(tokenOutBalanceRaw, tokenOutInfo.data.attributes.decimals);
      
        // Update your component's state with these formatted balances
        setTokenInBalance(tokenInBalanceFormatted);
        setTokenOutBalance(tokenOutBalanceFormatted);
      };
      
      

      useEffect(() => {
        if (tokenInInfo && tokenOutInfo) { // Ensure token info is loaded
          fetchTokenBalances();
        }
      }, [tokenInAddress, tokenOutAddress, signer, tokenInInfo, tokenOutInfo]);
      
      const handlePercentageInput = (percentage, balance, type) => {
        const decimalPercentage = percentage / 100;
        const calculatedValue = (Number(balance) * decimalPercentage).toFixed(5); // Use 5 decimal places or adjust as needed
      
        if (type === 'in') {
          setAmountIn(calculatedValue);
          setLastEdited('in'); // Ensure this triggers the useEffect for calculating "tokens out"
        } else if (type === 'out') {
          setAmountOut(calculatedValue);
          setLastEdited('out'); // Ensure this triggers the useEffect for calculating "tokens in"
        }
      };
      
      useEffect(() => {
        if (!amountOut || isNaN(amountOut) || !slippageTolerance || isNaN(slippageTolerance) || !tokenOutPrice || isNaN(tokenOutPrice)) {
            setCalculatedAmountOut(0);
            setCalculatedAmountOutUSD(0);
            return;
        }
    
        const amountOutNum = parseFloat(amountOut);
        const slippageToleranceNum = parseFloat(slippageTolerance) / 100;
        const tokenOutPriceNum = parseFloat(tokenOutPrice);
    
        const result = amountOutNum - (amountOutNum * slippageToleranceNum);
        const resultUSD = result * tokenOutPriceNum;
    
        setCalculatedAmountOut(result);
        setCalculatedAmountOutUSD(resultUSD);
    }, [amountOut, slippageTolerance, tokenOutPrice]); // Dependencies: Recalculate when these values change
    
    useEffect(() => {
      console.log("Modal Visible:", isModalVisible);
      console.log("Modal Message:", modalMessage);
    }, [isModalVisible, modalMessage]);
    



    return (
        <div className="swap-container">



          <div className="swap-interface">
          <button onClick={onClose}>Close</button>


            <TransactionModal
  isVisible={isModalVisible}
  message={modalMessage}
  onClose={() => setIsModalVisible(false)}
/>

    
          
          <div className='swap-interface-buttons'> 
        

</div>


            <div className="input-section">
             <div className='Token-In'>
    
              {defaultAddresses && (
<TokenDisplay
    label=""
    tokenInfo={tokenInInfo}
    address={tokenInAddress}
    setAddress={setTokenInAddress}
    defaultAddresses={defaultAddresses}
    signer={signer} // Pass the signer down to TokenDisplay
/>

)}

    
              {/* Amount Out */}
              <div className="form-control">
              {
  tokenInAddress.toLowerCase() === '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase() ? (
    <div className="token-balance">

    <span>Balance: </span>
    <strong>{walletBalance}</strong>
    </div>

  ) : (
    <div className="token-balance">
      <span>Balance: </span>
      <strong>{tokenInBalance}</strong>
    </div>
  )
}              
<input 
  id="amountIn" 
  type="text" // Using "text" allows more flexibility in handling input, including an empty string
  value={amountIn}
  onChange={(e) => handleAmountChange(e, 'in')} 
  onBlur={() => {
    if (!amountIn) { // If amountIn is empty, reset to "0" upon losing focus
      setAmountIn("0");
    }
  }}
  placeholder="0.0"
  pattern="\d*(\.\d*)?" // Updated pattern to allow decimal numbers
/>

{amountInUSDValue !== null && amountInUSDValue !== undefined && (
    <div>
      <span className="usd-value">≈ ${amountInUSDValue} USD</span>
      </div>

)}
                  </div>

              <div className="percentage-inputs">
  {tokenInAddress.toLowerCase() === '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase() ? (
    <>
      <button onClick={() => handlePercentageInput(25, walletBalance, 'in')}>25%</button>
      <button onClick={() => handlePercentageInput(50, walletBalance, 'in')}>50%</button>
      <button onClick={() => handlePercentageInput(75, walletBalance, 'in')}>75%</button>
      <button onClick={() => handlePercentageInput(99.99, walletBalance, 'in')}>100%</button>
    </>
  ) : (
    <>
      <button onClick={() => handlePercentageInput(25, tokenInBalance, 'in')}>25%</button>
      <button onClick={() => handlePercentageInput(50, tokenInBalance, 'in')}>50%</button>
      <button onClick={() => handlePercentageInput(75, tokenInBalance, 'in')}>75%</button>
      <button onClick={() => handlePercentageInput(99.99, tokenInBalance, 'in')}>100%</button>
    </>
  )}
</div>

</div>
              

              <button className="swap-tokens" onClick={swapTokens}>
  <FiRefreshCw className="swap-icon"/>
</button>
<div className='Token-In'>

              {/* Token Out Section */}
              <TokenDisplay
  label=""
  tokenInfo={tokenOutInfo}
  address={tokenOutAddress}
  setAddress={setTokenOutAddress}
  defaultAddresses={defaultAddresses}
  signer={signer} // Pass the signer down to TokenDisplay

/>

    
              {/* Amount Out */}
              <div className="form-control">
              {
  tokenOutAddress.toLowerCase() === '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase() ? (
    <div className="token-balance">

    <span>Balance: </span>
    <strong>{walletBalance}</strong>
    </div>

  ) : (
    <div className="token-balance">
      <span>Balance: </span>
      <strong>{tokenOutBalance}</strong>
    </div>
  )
}


<input 
  id="amountOut" 
  type="text" // Consider using "text" to avoid issues with clearing the field
  value={amountOut} 
  onChange={(e) => handleAmountChange(e, 'out')} 
  onBlur={() => handleBlur('out')}
  placeholder="0.0"
/>
   
{amountOutUSDValue !== null && amountOutUSDValue !== undefined && (
    <div>
      <span className="usd-value">≈ ${amountOutUSDValue} USD</span>
      </div>
)}             </div>

              <div className="percentage-inputs">
  {tokenOutAddress.toLowerCase() === '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'.toLowerCase() ? (
    <>
      <button onClick={() => handlePercentageInput(25, walletBalance, 'out')}>25%</button>
      <button onClick={() => handlePercentageInput(50, walletBalance, 'out')}>50%</button>
      <button onClick={() => handlePercentageInput(75, walletBalance, 'out')}>75%</button>
      <button onClick={() => handlePercentageInput(99.99, walletBalance, 'out')}>100%</button>
    </>
  ) : (
    <>
      <button onClick={() => handlePercentageInput(25, tokenOutBalance, 'out')}>25%</button>
      <button onClick={() => handlePercentageInput(50, tokenOutBalance, 'out')}>50%</button>
      <button onClick={() => handlePercentageInput(75, tokenOutBalance, 'out')}>75%</button>
      <button onClick={() => handlePercentageInput(99.99, tokenOutBalance, 'out')}>100%</button>
    </>
  )}
</div>
</div>
<div className='swap-container-button'> 

   <button className="swap-button1" onClick={handleSwap}>Swap</button>
</div>


              
              
              {slippageTolerance > 12 && (
                <p className="warning">Warning: High slippage tolerance might result in significant transaction cost.</p>
            )}
              
<div className="calculated-amount">
Minimum received: {calculatedAmountOut.toFixed(6)}
    <br />
    USD Value: ${calculatedAmountOutUSD.toFixed(2)}
    <br />
    Price Impact: {priceImpact}%
    

</div>

<div className="slippage-tolerance-container"> 
  Slippage Tolerance: {slippageTolerance}
  <i 
    className={`fas fa-edit ${isFormVisible ? 'active' : ''}`} 
    onClick={toggleFormVisibility}
    style={{ cursor: 'pointer' }}
  ></i>   
  </div>

  {/* Slippage Tolerance */}
  {isFormVisible && (

<div className="form-control">
  <label htmlFor="slippageTolerance">Slippage Tolerance (%):</label>
  <input 
id="slippageTolerance" 
type="text" // Change to text to improve control over formatting and validation
value={slippageTolerance}
onChange={e => {
// Extract the input value
const value = e.target.value;

// Allow empty string to let users clear the field or input values between 0.00 and 49
if (value === '' || (!isNaN(value) && parseFloat(value) >= 0.00 && parseFloat(value) <= 49)) {
setSlippageTolerance(value);
}
}}
onBlur={() => {
// If empty or less than minimum after losing focus, set to minimum value
if (slippageTolerance === '' || parseFloat(slippageTolerance) < 0.01) {
setSlippageTolerance('0.01');
}
// If greater than maximum allowed after losing focus, set to maximum value
else if (parseFloat(slippageTolerance) > 49) {
setSlippageTolerance('49');
}
}}
placeholder="e.g., 1" 
pattern="^49|(\d{0,2}(\.\d{0,2})?)$" // RegEx pattern to enforce formatting
/>

</div>
  )}


            </div>
            


            
          </div>
         
          
        </div>
        
      );
    };
   
      
    
    export default SwapComponent;