import React, { useState } from 'react';
import TokenExplorer from './TokenExplorer2';
import '../top-tokens-by-market-cap/TokenExplorer.css'; // Make sure to create this CSS file with the styles below


const Main_Token_Explorer2 = ({ signer, walletBalance, onSwapComplete, isSidebarVisible, extraComponent }) => {
    const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

      
  return (
    <div className={MainExplorer}>
<TokenExplorer 
                signer={signer} 
                walletBalance={walletBalance} 
                onSwapComplete={onSwapComplete} 
                isSidebarVisible={isSidebarVisible} 
                extraComponent={extraComponent} 
            />      
    </div>
  );
};

export default Main_Token_Explorer2;
