import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi } from './ContractABI'; // Adjust the path as needed
import './LotteryInterface.css'; // Importing our CSS file
import logoImage from './AIBOT_logo.png';
import Moralis from 'moralis';
import loadingGift from '../Swap/imagen.gif'; // Adjust the path as necessary
import successGift from '../Swap/success1.gif'; // Adjust the path as necessary
import errorGift from '../Swap/fail.png'; // Adjust the path as necessary
import TransactionModal from './TransactionModal';


const contractAddress = '0x35E43d19873E5cBa5a2C2462DAE6De2299B859d5';

// Web3Modal configuration
const projectId = 'b1b84b2b76426ba5ffae7eb4789161a0'; // Replace with your WalletConnect project ID
const infuraUrl = 'https://bsc-dataseed.binance.org/'; // BSC Mainnet RPC URL

const bscMainnet = {
  chainId: 56,
  name: 'BSC Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};


const metadata = {
  name: 'FAIB2 Lottery',
  description: 'Lottery',
  url: 'https://flokiaibot.com',
  icons: ['https://flokiaibot.com/static/media/AIBOT_logo.0bf99934a1aec060e3ee.png']
};

const TicketButton = ({ number, isSelected, isAvailable, isCurrentUserTicket, onClick }) => (
  <button
    className={`ticket-button ${isSelected ? 'selected' : ''} ${isAvailable ? 'available' : 'bought'} ${isCurrentUserTicket ? 'current-user' : ''}`}
    onClick={() => onClick(number)}
    disabled={!isAvailable}
  >
    {number}
  </button>
);


const Lottery = ({ signer, walletBalance, onSwapComplete, isSidebarVisible }) => {

  const [selectedTickets, setSelectedTickets] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [activeLottery, setActiveLottery] = useState([]);
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [totalTickets, setTotalTickets] = useState(0);
  const [soldTickets, setSoldTickets] = useState(0);
  const [winningProbability, setWinningProbability] = useState(0);
  const [selectedTicketCount, setSelectedTicketCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(null);
  const [activeLotteryId, setActiveLotteryId] = useState(null);
  const [pastLotteryData, setPastLotteryData] = useState(null);
  const [allPastLotteries, setAllPastLotteries] = useState([]);
  const [showPastLotteries, setShowPastLotteries] = useState(false);
  const [selectedLotteryId, setSelectedLotteryId] = useState(null);
  const [isLotteryDetailsVisible, setIsLotteryDetailsVisible] = useState(false);
  const [selectedLotteryIndex, setSelectedLotteryIndex] = useState(null);
  //const goerliChainId = '0x5'; // Hexadecimal chain ID for Goerli replace
  const bscChainId = '0x38'; // Hexadecimal chain ID for BSC Mainnet
  const [isTransactionModalVisible, setIsTransactionModalVisible] = useState(false);
  const [transactionModalMessage, setTransactionModalMessage] = useState('');
  const [modalImageSrc, setModalImageSrc] = useState(loadingGift);


  const fetchActiveLottery = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(infuraUrl);
        const contract = new ethers.Contract(contractAddress, abi, provider);
        
        const lotteryData = await contract.getActiveLottery();
        
        // Use optional chaining (?.) and nullish coalescing operator (??) to avoid errors
        setActiveLotteryId(lotteryData?.id?.toString() ?? '');

      // Process tickets to determine availability
      const tickets = lotteryData.tickets.map((address, index) => {
        const isCurrentUserTicket = address.toLowerCase() === currentUserAddress.toLowerCase();
        console.log(`Ticket ${index}:`, address, "Is Current User's Ticket:", isCurrentUserTicket);
        const total = lotteryData.tickets.length;
        const sold = lotteryData.tickets.filter(address => address !== '0x0000000000000000000000000000000000000000').length;
        
        setTotalTickets(total);
        setSoldTickets(sold);
        // Assuming each ticket is unique and the probability is calculated as the number of user's tickets over total tickets
        const userTicketsCount = lotteryData.tickets.filter(address => address.toLowerCase() === currentUserAddress.toLowerCase()).length;
    const probability = total > 0 ? ((userTicketsCount+selectedTicketCount) / total) * 100 : 0;
    setWinningProbability(probability);


        return {
          number: index,
          isAvailable: address === '0x0000000000000000000000000000000000000000',
          isCurrentUserTicket
        };
      });
      
  
      setActiveLottery(tickets);
    } catch (error) {
      console.error('Error fetching lottery data:', error);
    }
  };
  


  useEffect(() => {
    
    fetchActiveLottery();
  }, [currentUserAddress]); // include currentUserAddress in the dependency array

  
  useEffect(() => {
    const fetchUsdtPrice = async () => {
      try {
        const response = await Moralis.EvmApi.token.getTokenPrice({
          chain: "0x38",
          include: "percent_change",
          address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
        });

        // Assuming you want to display the USD price
        if (response && response.raw && response.raw.usdPrice) {
          setUsdtPrice(response.raw.usdPrice);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchUsdtPrice();
    const intervalId = setInterval(fetchUsdtPrice, 3600000); // Refresh every hour

    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setCurrentUserAddress(address);
        console.log("Current User Address:", address); // Add this line for debugging
      }
    };
  
    fetchCurrentUserAddress();
    fetchWalletBalance(); // Add this line to fetch and display wallet balance

  }, [signer]);

  const [currentChainId, setCurrentChainId] = useState('');


  // Function to switch to Goerli
  const switchToGoerli = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: bscChainId }],
      });
    } catch (error) {
      console.error('Error switching to Goerli:', error);
    }
  };

  // Check and switch network
  useEffect(() => {
    if (signer) {
      signer.getChainId().then(chainId => {
        setCurrentChainId(chainId.toString(16));
        if (chainId.toString(16) !== bscChainId) {
          switchToGoerli();
        }
      });
    }
  }, [signer]);




  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        // Convert the balance to Ether (wei to Ether) and round it to 4 decimal places
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    }
  };

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress('');
            setActiveLottery([]);
            setSelectedTickets([]);
          }
        });
      }
    };
  
    subscribeToAccountChanges();
  
    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);
  

  const handleTicketSelection = (ticketNumber) => {
    setSelectedTickets((prevSelectedTickets) => {
      // Determine if the ticket is already selected
      const isAlreadySelected = prevSelectedTickets.includes(ticketNumber);
  
      // Update the list of selected tickets
      const newSelectedTickets = isAlreadySelected
        ? prevSelectedTickets.filter(t => t !== ticketNumber)
        : [...prevSelectedTickets, ticketNumber];
  
      // Update the count of selected tickets
      const newSelectedTicketCount = newSelectedTickets.length;
      setSelectedTicketCount(newSelectedTicketCount);
  
      // Calculate the total cost in ETH
      if (usdtPrice) {
        const usdtTicketPrice = 3; // $3 per ticket in USDT
        // Total cost in USDT for all selected tickets
        const totalCostInUsdt = usdtTicketPrice * newSelectedTicketCount;
        // Convert the total cost in USDT to ETH
        const totalCostInEth = totalCostInUsdt / usdtPrice; 
      
        // Update the total cost in ETH, adjusting the precision as needed
        setTotalCost(totalCostInEth.toFixed(4)); // This keeps 4 decimal places for ETH
      }
      
  
      // Calculate the winning probability
      const userTicketsCount = activeLottery.filter(ticket => ticket.isCurrentUserTicket).length;
      const total = totalTickets;
      const probability = total > 0 ? ((userTicketsCount + newSelectedTicketCount) / total) * 100 : 0;
  
      // Update the winning probability
      setWinningProbability(probability);
  
      return newSelectedTickets;
    });
  };

  const fetchPastLotteryData = async (lotteryId) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(infuraUrl);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      
      const data = await contract.getLottery(lotteryId);
      
      // Process the winners and their corresponding winning tickets
      const winnersWithTickets = data.winners.map((winner, index) => ({
        address: winner,
        winningTicket: data.ticketsWinners[index].toString()
      }));
  
      setPastLotteryData({
        id: data.id.toString(),
        createdAt: data.createdAt.toString(),
        endedAt: data.endedAt.toString(),
        tickets: data.tickets,
        winners: winnersWithTickets
      });
    } catch (error) {
      console.error('Error fetching past lottery data:', error);
    }
  };
  

// Function to scroll to the previous or next past lottery
const scrollPastLottery = (direction) => {
  if (showPastLotteries) {
    const newIndex = selectedLotteryIndex + direction;
    if (newIndex >= 0 && newIndex < allPastLotteries.length) {
      setSelectedLotteryIndex(newIndex);
    }
  }
};


// Function to toggle the visibility of past lotteries
const togglePastLotteries = () => {
  setShowPastLotteries(!showPastLotteries);
  setSelectedLotteryIndex(0); // Reset selected lottery index when toggling visibility
};


  useEffect(() => {
    if (activeLotteryId) {
      // Assuming you want to fetch the previous lottery
      const pastLotteryId = activeLotteryId - 1;
      fetchPastLotteryData(pastLotteryId);
    }
  }, [activeLotteryId]);

  const handleLotterySelect = (id) => {
    setSelectedLotteryId(id);
  };
  

  const fetchAllPastLotteries = async () => {
    if (!activeLotteryId) return;
  
    try {
      const provider = new ethers.providers.JsonRpcProvider(infuraUrl);
      const contract = new ethers.Contract(contractAddress, abi, provider);
      let lotteries = [];
      for (let id = activeLotteryId - 1; id >= 1; id--) {
        const data = await contract.getLottery(id);
  
        const winnersWithTickets = data.winners.map((winner, index) => ({
          // Obscure the middle part of the address
          address: `${winner.substring(0, 6)}...${winner.substring(winner.length - 6)}`,
          winningTicket: data.ticketsWinners[index].toString(),
          place: index + 1
        }));
        
        lotteries.push({
          id: data.id.toString(),
          createdAt: data.createdAt.toString(),
          endedAt: data.endedAt.toString(),
          tickets: data.tickets,
          winners: winnersWithTickets
        });
      }
  
      // Sort lotteries by 'Ended At' date in descending order
      lotteries.sort((a, b) => b.endedAt - a.endedAt);
  
      setAllPastLotteries(lotteries);
    } catch (error) {
      console.error('Error fetching all past lotteries:', error);
    }
  };
  
  

  useEffect(() => {
    fetchAllPastLotteries();
  }, [activeLotteryId]); // Calls fetchAllPastLotteries whenever activeLotteryId changes
  
  
  

  const buyTickets = async (tickets) => {
    if (!signer) {
      setIsTransactionModalVisible(true);
      setTransactionModalMessage('Please connect your wallet to proceed.');
      return;
    }
  
    setIsTransactionModalVisible(true);
    setTransactionModalMessage('Processing your purchase...');
    setModalImageSrc(loadingGift);

    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      const usdtTicketPrice = 3; // $3 in USDT
      const ethTicketPrice = usdtTicketPrice / usdtPrice;
      
  
      // Round or truncate the ETH price to 18 decimal places
      const roundedEthTicketPrice = Number(ethTicketPrice.toFixed(18));
  
      // Convert the rounded ETH price to Wei
      const singleTicketPriceWei = ethers.utils.parseUnits(roundedEthTicketPrice.toString(), "ether");
  
      // Calculate total price
      const totalPrice = singleTicketPriceWei.mul(tickets.length);
  
        // Dynamic gas calculation
    const baseGasLimit = 500000; // Base gas limit for one ticket
    const additionalGasPerTicket = 90000; // Additional gas per ticket
    let totalGasLimit = baseGasLimit + (tickets.length - 1) * additionalGasPerTicket;

    // Adjust gas if buying the remaining tickets
    const remainingTickets = totalTickets - soldTickets; // totalTickets and soldTickets need to be defined or fetched
    if (remainingTickets - tickets.length === 0) {
      const extraGasForCompletingSale = 3000000; // Additional gas for extra computations when all tickets are sold
      totalGasLimit += extraGasForCompletingSale;
    }

    // Call the buyTickets function with the array of ticket numbers and the price of a single ticket
    const transaction = await contract.buyTickets(tickets, singleTicketPriceWei, {
      value: totalPrice,
      gasLimit: ethers.utils.hexlify(totalGasLimit) // Adjusted dynamic gas limit
    });
    await transaction.wait();
    setTransactionModalMessage('Tickets bought successfully! Transaction is complete.');
    setModalImageSrc(successGift);
    // Optionally close the modal after some time
    setTimeout(() => setIsTransactionModalVisible(false), 5000);
  
      // Reset the selected tickets
      setSelectedTickets([]);
  
      // Refetch the active lottery data
      await fetchActiveLottery();
  
      // Optionally update other related states if needed
      // e.g., user balance, total tickets, etc.
  
    } catch (error) {
      setTransactionModalMessage(`Transaction failed. Please Try Again.`);
      setModalImageSrc(errorGift);

    }
  };
  
  
  
  

  return (

    
    <div className='shimmer-effect'>
     <div className="faib-grid-container">

  <div className="faib-lottery-info">
    <span className="faib-info-label">Lottery #: </span>
    <span className="faib-info-value">{activeLotteryId}</span>
  </div>
</div>




      
      <div className="lottery-info-container">
        
  <div className="lottery-info">
    <span className="info-label">Tickets Available</span>
    <span className="info-value">{totalTickets - soldTickets} Ticket(s)</span>
  </div>
  <div className="lottery-info">
    <span className="info-label">Tickets Sold</span>
    <span className="info-value">{soldTickets} ticket(s)</span>
  </div>
  <div className="lottery-info">
    <span className="info-label">Probability to Win</span>
    <span className="info-value">{winningProbability.toFixed(2)}%</span>
  </div>
  <div className="lottery-info">
  <span className="info-label">Selected Tickets: </span>
  <span className="info-value">{selectedTicketCount}</span>
</div>
<div className="lottery-info animate-bounce">
  <span className="info-label">Cost: </span>
  <span className="info-value">{totalCost} BNB</span>
  <span className="info-value">{selectedTicketCount * 3} USDT</span> {/* 10 is the USDT price per ticket */}

</div>

<button
        className="buy-tickets-button"
        onClick={() => buyTickets(selectedTickets)}
        disabled={!signer || selectedTickets.length === 0}
      >
        Buy Selected Tickets
      </button>
      
</div>
<TransactionModal
    isVisible={isTransactionModalVisible}
    message={transactionModalMessage}
    imageSrc={modalImageSrc}
    onClose={() => setIsTransactionModalVisible(false)}
/>
<div className="legend">
  
  <div className="legend-item">
    <span className="legend-color selected"></span>
    Selected Tickets
  </div>
  <div className="legend-item">
    <span className="legend-color available"></span>
    Available Tickets
  </div>
  <div className="legend-item">
    <span className="legend-color bought"></span>
    Bought Tickets
  </div>
  <div className="legend-item">
    <span className="legend-color currentUser"></span>
    My Current Tickets
  </div>
</div>
      <div className="ticket-selection-area">
      {activeLottery.map(({ number, isAvailable, isCurrentUserTicket }) => (
  <TicketButton
    key={number} // Ensure 'number' is unique across all instances
    number={number}
    isSelected={selectedTickets.includes(number)}
    isAvailable={isAvailable}
    isCurrentUserTicket={isCurrentUserTicket}
    onClick={handleTicketSelection}
  />
))}

      </div>
    

      {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
      <div>
     
      <div className="action-buttons">
      
      
      {/* Button to toggle past lotteries view */}
      <button onClick={togglePastLotteries}  className="buy-tickets-button">
       {showPastLotteries ? 'Hide Past Lotteries' : 'Show Past Lotteries'}
     </button>
           {/* Scroll buttons for each past lottery */}
           {showPastLotteries && (
 <div className="scroll-past-lotteries">
   
   <button
     className="scroll-button prev"
     onClick={() => scrollPastLottery(1)}
   >
     &#8249; Prev
   </button>
   <button
     className="scroll-button next"
     onClick={() => scrollPastLottery(-1)}
   >
     Next &#8250;
   </button>
   
 </div>
)}

{/* Details of the selected lottery */}
{showPastLotteries && selectedLotteryIndex !== null && (
 <div className="selected-lottery-details">
 <h3>Lottery #: {allPastLotteries[selectedLotteryIndex].id}</h3>
 <p>Created At: {new Date(allPastLotteries[selectedLotteryIndex].createdAt * 1000).toLocaleString()}</p>
 <p>Ended At: {new Date(allPastLotteries[selectedLotteryIndex].endedAt * 1000).toLocaleString()}</p>
 <table className="winner-table">
     <thead>
       <tr>
         <th>Place</th>
         <th>Address</th>
         <th>Winning Ticket</th>
       </tr>
     </thead>
     <tbody>
       {allPastLotteries[selectedLotteryIndex].winners.map(
         (winner, winnerIndex) => (
           <tr key={winnerIndex}>
             <td>{winner.place}</td>
             <td>{winner.address}</td>
             <td>{winner.winningTicket}</td>
           </tr>
         )
       )} {/* Reverse the mapping here */}
     </tbody>
   </table>
 </div>
)}
   </div>

    </div>
  

    </div>
  );
}
export default Lottery;

