import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Use navigate if needed for redirection
import './gopluss.css'

const TokenDetails = ({ address }) => {
  const [tokenData, setTokenData] = useState(null);
  const [error, setError] = useState('');
  
  console.log("Address in TokenDetails:", address);


  useEffect(() => {
    // Define request options
    const options = { method: 'GET', headers: { accept: '*/*' } };

    // Fetch data from the GoPlus Labs API
    fetch(`https://api.gopluslabs.io/api/v1/token_security/56?contract_addresses=${address}`, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.code !== 1) { // Assuming '1' indicates success
          throw new Error(`API error! message: ${data.message}`);
        }
        // Directly access the nested result using the address as a key
        setTokenData(data.result[address]);
      })
      .catch(err => {
        console.error(err);
        setError(err.message || 'Failed to fetch token data');
      });
  }, [address]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!tokenData) {
    return <div>Loading...</div>;
  }

  function formatTotalSupply(totalSupply) {
    const supply = parseFloat(totalSupply);
    if (!isFinite(supply)) return 'Not Available';
  
    // Determine the abbreviation
    const units = ['K', 'M', 'B', 'T'];
    let abbreviation = '';
    let abbreviatedValue = supply;
  
    for (let i = units.length - 1; i >= 0; i--) {
      const size = Math.pow(1000, i + 1);
      if (supply >= size) {
        abbreviatedValue = supply / size;
        abbreviation = units[i];
        break;
      }
    }
  
    // Format the number with two decimal places if necessary and add abbreviation
    return abbreviatedValue.toLocaleString(undefined, {
      minimumFractionDigits: 0, 
      maximumFractionDigits: abbreviation ? 2 : 0
    }) + abbreviation;
  }
  

  function formatCreatorPercent(creatorPercent) {
    // Convert the string to a number and multiply by 100 for percentage
    const percent = parseFloat(creatorPercent) * 100;
    
    // Check if the result is a finite number to handle any potential conversion errors
    if (!isFinite(percent)) return 'Not Available';
  
    // Return the formatted percentage with two decimal places
    return percent.toFixed(2) + '%';
  }
  

  // Example of accessing token information
  return (
    
    <div className="token-info-container">

        <h2>Contract Security/Trading Security</h2>


        <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Total Supply</h2>
          <p>Total number of tokens in circulation.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatTotalSupply(tokenData.total_supply)}
</span>
       
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Owner Balance</h2>
          <p>Balance of tokens owned by the owner.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatTotalSupply(tokenData.owner_balance)}
</span>
          </div>
        </div>
      </div>





      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Creator Address</h2>
          <p>Address of the token's creator.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
  {tokenData?.creator_address ? `${tokenData.creator_address.substring(0, 3)}...${tokenData.creator_address.substring(tokenData.creator_address.length - 4)}` : 'Not Available'}
</span>
       
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Creator Balance</h2>
          <p>Balance of tokens owned by the creator.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatTotalSupply(tokenData.creator_balance)}
</span>
          </div>
        </div>
      </div>




      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Owner Percent</h2>
          <p>Percentage of total token supply owned by the owner.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatCreatorPercent(tokenData.owner_percent)}
</span>
       
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Creator Percent</h2>
          <p>Percentage of total token supply owned by the creator.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatCreatorPercent(tokenData.creator_percent)}
</span>
          </div>
        </div>
      </div>

      


      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>LP Total Supply</h2>
          <p>Total number of liquidity pool (LP) tokens in circulation.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatTotalSupply(tokenData.lp_total_supply)}
</span>
       
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Holders</h2>
          <p>Number of token holders.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatTotalSupply(tokenData.holder_count)}
</span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Buy Tax</h2>
          <p>Extra fees on Buy transactions.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatCreatorPercent(tokenData.buy_tax)}
</span>
       
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Sell Tax</h2>
          <p>Extra fees on Sell transactions.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
          {formatCreatorPercent(tokenData.sell_tax)}
</span>
          </div>
        </div>
      </div>



        
      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Open Source</h2>
          <p>The code is available for anyone to see.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_open_source ? 'yes' : 'no'}>
            {tokenData?.is_open_source ? 'Yes' : 'No'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Proxy Contract</h2>
          <p>A contract that acts on behalf of another, adding features without changing the original.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_proxy ? 'yes' : 'no'}>
            {tokenData?.is_proxy ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>


      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Mintable</h2>
          <p>New tokens can be created after the initial creation.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_mintable ? 'yes' : 'no'}>
            {tokenData?.is_mintable ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Owner Address</h2>
          <p>The digital wallet address of the current token owner.</p>
          </div>
          <div className='token-info-right'> 
          <span className='white'>
  {tokenData?.owner_address ? `${tokenData.owner_address.substring(0, 3)}...${tokenData.owner_address.substring(tokenData.owner_address.length - 4)}` : 'Not Available'}
</span>
          </div>
        </div>
      </div>


      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Retrieve Ownership</h2>
          <p>Ability to take back control of the token.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.can_take_back_ownership ? 'yes' : 'no'}>
            {tokenData?.can_take_back_ownership ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Balance Mod</h2>
          <p>Changes to the number of tokens in someone's wallet.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.owner_change_balance ? 'yes' : 'no'}>
            {tokenData?.owner_change_balance ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Hidden Owner</h2>
          <p>The owner's identity is not publicly disclosed.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.hidden_owner ? 'yes' : 'no'}>
            {tokenData?.hidden_owner ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Self Destructd</h2>
          <p>Token can be programmed to disappear.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.selfdestruct ? 'yes' : 'no'}>
            {tokenData?.selfdestruct ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>External Call Risk</h2>
          <p>Risk from the token interacting with outside contracts.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.external_call ? 'yes' : 'no'}>
            {tokenData?.external_call ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Buy Available</h2>
          <p>Restrictions on buying or selling the token..</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.cannot_buy ? 'yes' : 'no'}>
            {tokenData?.cannot_buy ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Cannot Sell All</h2>
          <p>Restrictions on buying or selling the token.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.cannot_sell_all ? 'yes' : 'no'}>
            {tokenData?.cannot_sell_all ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Taxes Mod</h2>
          <p>Tax Modifiable.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.slippage_modifiable ? 'yes' : 'no'}>
            {tokenData?.slippage_modifiable ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>


      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Honeypot</h2>
          <p>A trap making it impossible to sell the token later.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_honeypot ? 'yes' : 'no'}>
            {tokenData?.is_honeypot ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Transfer Pausable</h2>
          <p>Ability to stop transactions temporarily.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.transfer_pausable ? 'yes' : 'no'}>
            {tokenData?.transfer_pausable ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>




      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Blacklisted</h2>
          <p>Lists controlling who can use or hold the token.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_blacklisted ? 'yes' : 'no'}>
            {tokenData?.is_blacklisted ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Whitelisted</h2>
          <p>Lists controlling who can use or hold the token.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_whitelisted ? 'no' : 'yes'}>
            {tokenData?.is_whitelisted ? 'Yes' : 'No'}
          </span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Anti-Whale</h2>
          <p>Measures against large market-manipulating transactions.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.is_anti_whale ? 'yes' : 'no'}>
            {tokenData?.is_anti_whale ? 'No' : 'Yes'}
          </span>
          </div>
        </div>
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Anti-Whale Mod</h2>
          <p>Ability to modify the Anti-Whale measures.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.anti_whale_modifiable ?  'yes' : 'no'}>
            {tokenData?.anti_whale_modifiable ?  'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>



      <div className="token-info-row">
        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Trading Cooldown</h2>
          <p>Waiting period between trades.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.trading_cooldown ? 'yes' : 'no'}>
            {tokenData?.trading_cooldown ? 'No' : 'Yes'}
          </span>
          </div>
        </div>

        <div className="token-info">
            <div className='token-info-left'> 
          <h2>Address Slippage Mod</h2>
          <p>Modification of the user's personal slippage tolerance.</p>
          </div>
          <div className='token-info-right'> 
          <span className={tokenData?.personal_slippage_modifiable ?  'yes' : 'no'}>
            {tokenData?.personal_slippage_modifiable ?  'No' : 'Yes'}
          </span>
          </div>
        </div>
      </div>





    </div>
 
  );
  
};

export default TokenDetails;