import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Moralis from 'moralis';

// Initialize Moralis
Moralis.start({
  apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImUyZDliNjE0LWNlMzgtNGZmMC1iOWI4LWJhMjQ1ZjQ1ZmJhMSIsIm9yZ0lkIjoiMzg1NjEwIiwidXNlcklkIjoiMzk2MjIzIiwidHlwZUlkIjoiMDEzNTk2NmItZTBhZC00ZTk5LWI2ZjYtMGNmNDgyOGM1MDk4IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTE4Nzg4MDksImV4cCI6NDg2NzYzODgwOX0.fL3UJHxgbkH76Ca5uTqKIPP_o4_WjShXNv1KNOMeQHs"
});




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
