import React from 'react';
import ConnectButton from './staking'; // Adjust the import path as necessary

function stakingpage({ signer, walletBalance, onSwapComplete, isSidebarVisible }) {
  const MainExplorer = `main-explorer ${isSidebarVisible ? '' : 'full-main-explorer'}`;

  return (
    <div className={MainExplorer}>
      <ConnectButton signer={signer} walletBalance={walletBalance} onSwapComplete={onSwapComplete} />
    </div>
  );
}

export default stakingpage;
